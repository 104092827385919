import React, { useState, useEffect } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import axios from "axios";
import guardIcon from "../../assets/icons/guard.svg";
import maintanenceIcon from "../../assets/icons/maintanence.svg";
import { ChangeEvent } from "react";

const apiPort = process.env.REACT_APP_API_PORT;

interface SuperUser {
  _id: string;
  name: string;
  password: string;
}

const AdminConfiguration = () => {
  const [superUsers, setSuperUsers] = useState<SuperUser[]>([]);
  const [maintainMessage, setMaintainMessage] = useState({
    message: "",
    status: false,
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false); // Success notification state
  const [showError, setShowError] = useState(false); // Error notification state

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const superUserResponse = await axios.get(
          `${apiPort}/api/users/getSuperUsers`
        );
        if (superUserResponse.data) {
          setSuperUsers(superUserResponse.data);
        } else {
          console.warn("No Super users found.");
        }

        const maintainMessageResponse = await axios.get(
          `${apiPort}/api/maintain`
        );
        if (maintainMessageResponse.data) {
          setMaintainMessage(maintainMessageResponse.data);
        } else {
          console.warn("No Maintainence found.");
        }
      } catch (error) {
        console.log("Error fetching: ", error);
      }
    };

    fetchConfigs();
  }, []);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    setMaintainMessage({ ...maintainMessage, [key]: e.target.value });
  };

  const handleToggleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof typeof maintainMessage
  ) => {
    setMaintainMessage({ ...maintainMessage, [field]: e.target.checked });
  };

  const handleSave = async () => {
    try {
      await axios.post(`${apiPort}/api/maintain`, maintainMessage);
      setShowSuccess(true); // Show success notification
      setShowError(false); // Hide error notification if it's visible
      setTimeout(() => setShowSuccess(false), 3000); // Hide after 3 seconds
    } catch (error) {
      console.error("Error saving settings:", error);
      setShowError(true); // Show error notification
      setShowSuccess(false); // Hide success notification if it's visible
      setTimeout(() => setShowError(false), 3000); // Hide after 3 seconds
    }
  };

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    if (newPassword !== confirmPassword) {
      setErrorMessage("New password and confirm password don't match");
      return;
    }

    try {
      const response = await axios.put(
        `${apiPort}/api/users/password`,
        {
          currentPassword,
          newPassword,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setSuccessMessage("Password changed successfully");
        setErrorMessage("");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setErrorMessage("Error changing password. Please try again.");
    }
  };

  return (
    <DashboardLayout>
      <div className="flex">
        <div className="w-1/2 m-1">
          <div className="p-6 mt-1">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15  mr-1">
                  <img src={guardIcon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">Seguridad</h3>
                  <div className="text-gray-600 mb-4">
                    Desde esta sección se podrá actualizar el usuario y
                    contraseña de los zusuarios administradores “SuperUser”.
                  </div>
                </div>
              </div>
              <div>
                <div className="category_table">
                  <table className="min-w-full bg-white shadow-md rounded mb-4">
                    <thead>
                      <tr>
                        <th>Nombre completo</th>
                        <th>Correo</th>
                        <th>Estado</th>
                        <th>Herramientas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {superUsers.map((superUse: any) => (
                        <tr key={superUse._id} className="border">
                          <td className="text-center px-2 py-2">
                            {superUse.name}
                          </td>
                          <td className="text-center px-2 py-2">
                            {superUse.email}
                          </td>
                          <td className="text-center px-2 py-2">
                            {superUse.status}
                          </td>
                          <td className="text-center px-2 py-2">
                            <button
                              //   onClick={() => deleteCategory(category._id)}
                              className="bg-gray-100 text-gray-800  px-4 py-2 rounded-full"
                            >
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="text-center">
                  {/* Save Button */}
                  <button
                    className="bg-dashcolor text-white px-6 py-2 rounded-full"
                    // onClick={() => setIsCreateCategoryModalOpen(true)}
                  >
                    Crear Admin
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="p-6 mt-1">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15  mr-1">
                  <img src={guardIcon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">Seguridad</h3>
                  <div className="text-gray-600 mb-4">
                    Desde esta sección podrás actualizar tu contraseña.{" "}
                  </div>
                </div>
              </div>
              <div>
                {errorMessage && (
                  <div className="text-red-500 mb-4">{errorMessage}</div>
                )}
                {successMessage && (
                  <div className="text-green-500 mb-4">{successMessage}</div>
                )}
                <h4 className="font-semibold">
                    Recomendaciones
                </h4>
                <p className="text-gray-600">
                    Usa una contraseña segura de 6 a 10 caracteres.
                </p>
                <form onSubmit={handlePasswordChange} className="mt-2">
                  <div className="mb-4">
                    <input
                      type="password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      className="border p-2 rounded w-full"
                      placeholder="Escribe aquí la contraseña actual"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <input
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="border p-2 rounded w-full"
                      placeholder="Escribe aquí la NUEVA contraseña "
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="border p-2 rounded w-full"
                      placeholder="Escribe aquí nuevamente la NUEVA contraseña "
                      required
                    />
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="bg-dashcolor text-white px-6 py-2 rounded-full"
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/2 m-1">
          <div className="p-6">
            {/* Voice Server Section */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15 mr-1">
                  <img src={maintanenceIcon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">Mantenimiento</h3>
                  <div className="text-gray-600 mb-4">
                  Desde esta sección se podrá activar las ventanas de mantenimiento de la plataforma, de esta forma los usuarios no podrán ingresar.
                  </div>
                </div>
              </div>

              {/* LOVO Server Configuration */}
              <div className="mb-6">
                <h4 className="font-semibold">Mensaje de mantenimiento</h4>
                <p className="text-gray-600">
                    Aquí puede colocar un texto para informar sobre el mantenimiento.
                </p>
                <div className="flex">
                  <div className="w-3/4">
                    <textarea
                      className="border p-2 w-full mt-2 mb-4 rounded"
                      placeholder="Escribe aquí..."
                      value={maintainMessage.message}
                      onChange={(e) =>
                        handleInputChange(e, "voiceServer_lovoApiKey")
                      }
                      disabled={!maintainMessage.status}
                    ></textarea>
                  </div>
                  <div className="w-1/4 ml-6 mt-4">
                    {/* Toggle Switch for LOVO */}
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={maintainMessage.status}
                        onChange={(e) => handleToggleChange(e, "status")}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="text-center">
                {/* Save Button */}
                <button
                    className="bg-dashcolor text-white px-6 py-2 rounded-full"
                    onClick={handleSave}
                    >
                  Guardar
                </button>
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AdminConfiguration;
