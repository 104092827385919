import React, { useState } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import DashboardLayout from "../../layouts/DashboardLayout";

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
const apiPort = process.env.REACT_APP_API_PORT

interface IUser {
  _id: string;
  name: string;
  email: string;
  currentPlan: { name: string; monthlyCost: number } | null;
}

interface IPlan {
  _id: string;
  name: string;
  monthlyCost: number;
}

const CheckoutForm = ({ userId, planId }: { userId: string; planId: string }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

 

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    // Create the payment intent when the user submits the form
    try {
      const { data } = await axios.post(`${apiPort}/api/payment/create`, {
        userId,
        planId,
      });

      // Set clientSecret and paymentId after creating the payment intent
      setClientSecret(data.clientSecret);
      setPaymentId(data.paymentId);

      // Confirm payment only after clientSecret is available
      const result = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
        },
      });

      if (result.error) {
        setError(result.error.message || "Payment error occurred");
        setLoading(false);
      } else if (result.paymentIntent?.status === "succeeded") {
        await axios.post(`${apiPort}/api/payment/confirm`, {
          paymentId: data.paymentId,
          stripeId: result.paymentIntent.id,
        });
        setSuccess(true);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error creating or confirming payment:", error);
      setError("Payment failed. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div>
      <h3>Complete your payment</h3>
      <form onSubmit={handleSubmit}>
        <CardElement />
        <button type="submit" disabled={!stripe || loading}>
          {loading ? "Processing..." : "Pay"}
        </button>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && <p style={{ color: "green" }}>Payment successful!</p>}
      </form>
    </div>
  );
};

const CreatePaymentPage = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [plans, setPlans] = useState<IPlan[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [selectedPlanId, setSelectedPlanId] = useState<string>("");

  const fetchUsersAndPlans = async () => {
    try {
      const usersResponse = await axios.get(`${apiPort}/api/users/getUsers`);
      const plansResponse = await axios.get(`${apiPort}/api/plans`);
      setUsers(usersResponse.data);
      setPlans(plansResponse.data);
    } catch (error) {
      console.error("Error fetching users or plans:", error);
    }
  };

  React.useEffect(() => {
    fetchUsersAndPlans();
  }, []);

  return (
    <DashboardLayout>
      <div>
        <h2 className="mb-4">Create Payment</h2>

        {/* User Selection */}
        <div className="mb-4">
          <label>Select User:</label>
          <select
            value={selectedUserId}
            onChange={(e) => setSelectedUserId(e.target.value)}
          >
            <option value="">Select User</option>
            {users.map((user) => (
              <option key={user._id} value={user._id}>
                {user.name} ({user.email})
              </option>
            ))}
          </select>
        </div>

        {/* Plan Selection */}
        <div className="mb-4">
          <label>Select Plan:</label>
          <select
            value={selectedPlanId}
            onChange={(e) => setSelectedPlanId(e.target.value)}
          >
            <option value="">Select Plan</option>
            {plans.map((plan) => (
              <option key={plan._id} value={plan._id}>
                {plan.name} (${plan.monthlyCost}/month)
              </option>
            ))}
          </select>
        </div>

        {/* Show Checkout Form when both user and plan are selected */}
        {selectedUserId && selectedPlanId && (
          <Elements stripe={stripePromise}>
            <CheckoutForm userId={selectedUserId} planId={selectedPlanId} />
          </Elements>
        )}
      </div>
    </DashboardLayout>
  );
};

export default CreatePaymentPage;
