import React, { useState } from "react";
import axios from "axios";
import { TextLayout } from "./TextLayoutPage";

interface EditTextLayoutModalProps {
  textlayout: TextLayout;
  onClose: () => void;
  onSave: (updateTextLayout: TextLayout) => void;
}

const apiPort = process.env.REACT_APP_API_PORT;

const EditTextLayoutModal: React.FC<EditTextLayoutModalProps> = ({
  textlayout,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState({
    name: textlayout.name,
    planType: textlayout.planType,
    status: textlayout.status,
    description: textlayout.description,
  });

  const [isSaving, setIsSaving] = useState(false); // For loading state

  // Handle input changes
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const response = await axios.put(
        `${apiPort}/api/text-layouts/${textlayout._id}`,
        formData, // Sending JSON data
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      onSave(response.data);
      onClose();
    } catch (error) {
      console.error("Error updating text layout:", error);
      alert("Error updating text layout.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-lg h-auto max-h-[80%] overflow-y-auto">
        <h2 className="text-lg font-bold mb-4">Editar plantilla</h2>

        {/* Name Field */}
        <div className="mb-4">
          <label className="block text-sm">Nombre</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Nombre"
            className="mb-4 p-2 border rounded w-full"
            required
          />
        </div>

        {/* Plan Type Select Field */}
        <div className="mb-4">
          <label className="block text-sm">Tipo de plan</label>
          <select
            name="planType"
            value={formData.planType}
            onChange={handleChange}
            className="mb-4 p-2 border rounded w-full"
            required
          >
            <option value="Normal">Normal</option>
            <option value="Pro">Pro</option>
          </select>
        </div>

        {/* Status Select Field */}
        <div className="mb-4">
          <label className="block text-sm">Estado</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="mb-4 p-2 border rounded w-full"
            required
          >
            <option value="Active">Activo</option>
            <option value="Inactive">Inactivo</option>
            <option value="Pending">Pendiente</option>
          </select>
        </div>

        {/* Description Field */}
        <div className="mb-4">
          <label className="block text-sm">Descripción</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Descripción"
            className="mb-4 p-2 border rounded w-full"
            rows={4}
            required
          ></textarea>
        </div>

        {/* Save and Cancel Buttons */}
        <div className="flex justify-end space-x-4">
          <button onClick={onClose} className="px-4 py-2 bg-gray-300 rounded">
            Cancelar
          </button>
          <button
            onClick={handleSave}
            className={`px-4 py-2 bg-blue-500 text-white rounded ${
              isSaving ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isSaving}
          >
            {isSaving ? "Guardando..." : "Guardar"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTextLayoutModal;
