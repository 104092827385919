import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineFileDownload } from "react-icons/md";

import axios from "axios";

interface ShapeUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const apiPort = process.env.REACT_APP_API_PORT;


const ShapeUploadModal: React.FC<ShapeUploadModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [title, setTitle] = useState<string>(""); // Title state for the shape

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/svg+xml": [".svg"],
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
    },
    onDrop: (acceptedFiles: File[]) => {
      setFiles(acceptedFiles);
    },
  });

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("name", title); // Include the title in the form data
    files.forEach((file) => {
      formData.append("image", file);
    });

    try {
      await axios.post(`${apiPort}/api/shapes`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error uploading shape:", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-lg h-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Cargar nuevas formas</h2>
          <button onClick={onClose} className="text-2xl">
            &times;
          </button>
        </div>

        {/* Title input field */}
        <div className="mb-4">
          <label className="block text-sm mb-2 font-semibold">Título</label>
          <input
            type="text"
            className="border p-2 rounded w-full"
            placeholder="Escribe un título para la forma"
            value={title}
            onChange={(e) => setTitle(e.target.value)} // Update the title state
          />
        </div>

        {/* Dropzone area */}
        <div>
          <p className="text-gray-500">
            Arrastra los archivos .SVG a esta área para cargarlos en la
            plataforma.
          </p>
          <div
            {...getRootProps({
              className:
                "border-dashed border-2 border-gray-400 p-6 flex justify-center items-center rounded-lg cursor-pointer",
            })}
          >
            <input {...getInputProps()} />
            <MdOutlineFileDownload />
          </div>
        </div>

        {/* Display selected files */}
        {files.length > 0 && (
          <ul className="mt-4">
            {files.map((file, index) => (
              <li key={index} className="text-sm text-gray-600">
                {file.name}
              </li>
            ))}
          </ul>
        )}

        {/* Action buttons */}
        <div className="flex justify-between mt-6">
          <button
            className="bg-gray-300 text-black px-4 py-2 rounded-full"
            onClick={onClose}
          >
            Cancelar
          </button>
          <button
            className="bg-dashcolor text-white px-4 py-2 rounded-full"
            onClick={handleSubmit}
            disabled={!title || files.length === 0} // Disable if no title or files
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShapeUploadModal;
