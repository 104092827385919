import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  FaChevronDown,
  FaChevronUp, 
} from "react-icons/fa"; // Import relevant icons
import { MdKeyboardArrowRight } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";
import { setSelectedItem } from "../redux/slices/sidebarSlice";
import { RootState } from "../redux/store";

import logoImage from "../assets/img/logo.png"; // Import the logo image
import iconHome from '../assets/icons/home.svg';
import satellite from "../assets/icons/satellite.svg";
import engergyIcon from '../assets/icons/energy.svg';
import rocketIcon from '../assets/icons/rocket.svg';
import bookIcon from '../assets/icons/book1.svg';
import sicenceIcon from '../assets/icons/science.svg'


const Sidebar = ({ isOpen }: { isOpen: boolean }) => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [plansMenuOpen, setPlansMenuOpen] = useState(false);
  const [paymentMenuOpen, setPaymentMenuOpen] = useState(false);
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const dispatch = useDispatch();
  const selectedItem = useSelector(
    (state: RootState) => state.sidebar.selectedItem
  );


  const handleItemClick = (item: string) => {   
    dispatch(setSelectedItem(item));
  };

  return (
    <aside
      className={`relative bg-dashcolor text-white min-h-screen transition-all ${
        isOpen ? "w-64" : "w-16"
      } duration-300`}
    >
      <div className="p-4 flex items-center">
        {/* Sidebar Logo */}
        <img
          src={logoImage}
          alt="Logo"
          className={`transition-all duration-300 ${
            isOpen ? "" : "w-0"
          } overflow-hidden`}
        />
      </div>

      <ul className="mt-[30px]">
        <li
          onClick={() => handleItemClick("Dashboard")}
          className={`p-4 mb-3 flex items-center hover:bg-dashboard hover:text-white-700 transition-all cusor-pointer ${
            isActive("/dashboard")
              ? "bg-gray-100 text-gray-700 font-bold rounded-l-[25px] rounded-r-none ml-[5px]"
              : ""
          }`}
        >
          <img
            src={iconHome}
            alt="iconHome"
            className={`transition-all duration-300 ${
              isOpen ? "w-5" : "w-5"
            } overflow-hidden ${
              isActive("/dashboard") ? "fill-purple-700" : "fill-current"
            }`}
          />
          <Link
            to="/dashboard"
            className={`ml-4 ${isOpen ? "block" : "hidden"}`}
          >
            Dashboard
          </Link>
        </li>
        <li
          onClick={() => handleItemClick("Gestión de usuarios")}
          className={`p-4 mb-3 flex items-center hover:bg-dashboard hover:text-white-700 transition-all cusor-pointer ${
            isActive("/user/manage")
              ? "bg-gray-100 text-gray-700 font-bold rounded-l-[25px] rounded-r-none ml-[5px]"
              : ""
          }`}
        >
          <img
            src={satellite}
            alt="satellite"
            className={`transition-all duration-300 ${
              isOpen ? "w-5" : "w-5"
            } overflow-hidden`}
          />
          <Link
            to="/user/manage"
            className={`ml-4 ${isOpen ? "block" : "hidden"}`}
          >
            Gestión de usuarios
          </Link>
        </li>

        <li
          onClick={() => handleItemClick("Gestión de planes")}
          className={`p-4 mb-3 flex items-center hover:bg-dashboard hover:text-white-700 transition-all cusor-pointer ${
            isActive("/plan-management")
              ? "bg-gray-100 text-gray-700 font-bold rounded-l-[25px] rounded-r-none ml-[5px]"
              : ""
          }`}
        >
          <img
            src={engergyIcon}
            alt="engergyIcon"
            className={`transition-all duration-300 ${
              isOpen ? "w-5" : "w-5"
            } overflow-hidden`}
          />
          <Link
            to="/plan-management"
            className={`ml-4 ${isOpen ? "block" : "hidden"}`}
          >
            Gestión de planes
          </Link>
        </li>

        {/* Plans Management Dropdown */}
        <li
          className={`p-4 mb-3 block items-center hover:bg-dashboard transition-all cusor-pointer ${
            isActive("/editor/templates")
              ? "bg-gray-100 text-gray-700 font-bold rounded-l-[25px] rounded-r-none ml-[5px]"
              : ""
          }
           ${
             isActive("/editor/text-layouts")
               ? "bg-gray-100 text-gray-700 font-bold rounded-l-[25px] rounded-r-none ml-[5px]"
               : ""
           }
            ${
              isActive("/editor/shapes-bank")
                ? "bg-gray-100 text-gray-700 font-bold rounded-l-[25px] rounded-r-none ml-[5px]"
                : ""
            }
             ${
               isActive("/editor/sounds-bank")
                 ? "bg-gray-100 text-gray-700 font-bold rounded-l-[25px] rounded-r-none ml-[5px]"
                 : ""
             }
            ${
              isActive("/editor/settings")
                ? "bg-gray-100 text-gray-700 font-bold rounded-l-[25px] rounded-r-none ml-[5px]"
                : ""
            }
            ${
              plansMenuOpen
                ? "bg-gray-100 text-gray-700 font-bold rounded-l-[25px] rounded-r-none ml-[5px]"
                : ""
            }          
          `}
          onClick={() => setPlansMenuOpen(!plansMenuOpen)}
        >
          <div className="flex items-center">
            <img
              src={rocketIcon}
              alt="rocketIcon"
              className={`transition-all duration-300 ${
                isOpen ? "w-5" : "w-5"
              } overflow-hidden`}
            />
            <span className={`ml-4 mr-2 ${isOpen ? "block" : "hidden"}`}>
              Gestión del editor
            </span>
            {isOpen && (plansMenuOpen ? <FaChevronUp /> : <FaChevronDown />)}
          </div>

          {plansMenuOpen && isOpen && (
            <ul className="ml-8 bg-gray-100 text-gray-700 font-bold rounded-bl-[25px] rounded-r-none ml-[5px]">
              <li
                onClick={() => handleItemClick("Plantilllas")}
                className="p-2 ml-5 flex hover:bg-dashboard hover:text-white-700"
              >
                <Link to="/editor/templates" className="flex">
                  <MdKeyboardArrowRight className="mt-1" />
                  Plantilllas
                </Link>
              </li>
              <li
                onClick={() => handleItemClick("Diseños de textos")}
                className="p-2 ml-5 hover:bg-dashboard hover:text-white-700"
              >
                <Link to="/editor/text-layouts" className="flex">
                  <MdKeyboardArrowRight className="mt-1" />
                  Diseños de textos
                </Link>
              </li>
              <li
                onClick={() => handleItemClick("Banco de formas")}
                className="p-2 ml-5 hover:bg-dashboard hover:text-white-700"
              >
                <Link to="/editor/shapes-bank" className="flex">
                  <MdKeyboardArrowRight className="mt-1" />
                  Banco de formas
                </Link>
              </li>
              <li
                onClick={() => handleItemClick("Banco de sonidos")}
                className="p-2 ml-5 hover:bg-dashboard hover:text-white-700"
              >
                <Link to="/editor/sounds-bank" className="flex">
                  <MdKeyboardArrowRight className="mt-1" />
                  Banco de sonidos
                </Link>
              </li>
              <li
                onClick={() => handleItemClick("Configuración")}
                className="p-2 ml-5 hover:bg-dashboard hover:text-white-700"
              >
                <Link to="/editor/settings" className="flex">
                  <MdKeyboardArrowRight className="mt-1" />
                  Configuración
                </Link>
              </li>
            </ul>
          )}
        </li>


        <li
          onClick={() => handleItemClick("Gestión de usuarios")}
          className={`p-4 mb-3 flex items-center hover:bg-dashboard hover:text-white-700 transition-all cusor-pointer ${
            isActive("/payments")
              ? "bg-gray-100 text-gray-700 font-bold rounded-l-[25px] rounded-r-none ml-[5px]"
              : ""
          }`}
        >
          <img
            src={bookIcon}
            alt="satellite"
            className={`transition-all duration-300 ${
              isOpen ? "w-5" : "w-5"
            } overflow-hidden`}
          />
          <Link
            to="/payments"
            className={`ml-4 ${isOpen ? "block" : "hidden"}`}
          >
            Gestión de pagos
          </Link>
        </li>

       

        <li 
          onClick={() => handleItemClick("Configuraciones")} 
          className={`p-4 mb-3 flex items-center hover:bg-dashboard hover:text-white-700 transition-all cusor-pointer ${
            isActive("/settings")
              ? "bg-gray-100 text-gray-700 font-bold rounded-l-[25px] rounded-r-none ml-[5px]"
              : ""
          }`}
         
        >
          <img
            src={sicenceIcon}
            alt="sicenceIcon"
            className={`transition-all duration-300 ${
              isOpen ? "w-5" : "w-5"
            } overflow-hidden`}
          />
          <Link
            to="/settings"
            className={`ml-4 ${isOpen ? "block" : "hidden"}`}
          >
            Configuraciones
          </Link>
        </li>
      </ul>

      {/* Informational Text at the Bottom */}
      <div className="mt-[150px] bottom-20 p-4 w-full text-center text-xs text-white">
        <div className="font-semibold ">VidBuilder - Dale vida a tus ideas</div>
        <div className="mt-1 font-sm">© 2024 Todos los derechos reservados</div>
        <div className="mt-4">
          Desarrollado con <span className="text-red-500">❤</span> por Torrente
          Dev
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
