import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

interface UserStatusChartProps {
    description: string;
    mora: string;
    activos: string;
    pendiente: string;
    inactivo: string;
}

const UserStatusChart: React.FC<UserStatusChartProps> = ({ description, mora, activos, pendiente, inactivo }) => {

  const moraData = {
    labels: ["Mora"],
    datasets: [
      {
        data: [100], // 66% used, 34% free
        backgroundColor: ["#EA518D"], // Orange for used, gray for free
        hoverBackgroundColor: ["#EA518D"],
      },
    ],
  };

  const activosData = {
    labels: ["Activos"],
    datasets: [
      {
        data: [100], // 66% used, 34% free
        backgroundColor: ["#2BC155"], // Orange for used, gray for free
        hoverBackgroundColor: ["#2BC155"],
      },
    ],
  };

  const pendingData = {
    labels: ["Pendiente"],
    datasets: [
      {
        data: [100], // 66% used, 34% free
        backgroundColor: ["#FF8E26"], // Orange for used, gray for free
        hoverBackgroundColor: ["#FF8E26"],
      },
    ],
  };

  const inactiveData = {
    labels: ["Inactivo"],
    datasets: [
      {
        data: [100], // 66% used, 34% free
        backgroundColor: ["#D3D3D3"], // Orange for used, gray for free
        hoverBackgroundColor: ["#D3D3D3"],
      },
    ],
  };

  

  const options = {
    cutout: "80%", // Size of the doughnut hole
    responsive: true,
    maintainAspectRatio: false, // Ensure we can control the aspect ratio manually
    plugins: {
      legend: {
        display: false, // Hide legend to show usage percentage
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
    },
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      {/* Main Disk Usage Chart */}
      
      {/* CPU and RAM Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-6 gap-6 ">
        
        <div className="col-span-1 lg:col-span-2">
          <div className="relative ">
            {description}
           </div>
         
        </div>

        <div className="col-span-1 lg:col-span-1 text-center">
          <div className="relative h-12 w-12 m-auto">
            <Doughnut data={moraData} options={options} />
            <div className="absolute inset-0 flex justify-center items-center text-sm font-semibold">
              {mora}
            </div>
          </div>
          <p className="mt-2 font-semibold">MOra</p>
        </div>

        {/* RAM Usage */}
        <div className="col-span-1 lg:col-span-1 text-center">
          <div className="relative h-12 w-12 m-auto">
            <Doughnut data={activosData} options={options} />
            <div className="absolute inset-0 flex justify-center items-center text-sm font-semibold">
              {activos}
            </div>
          </div>
          <p className="mt-2 font-semibold">Activos</p>
        </div>

        <div className="col-span-1 lg:col-span-1 text-center">
          <div className="relative h-12 w-12 m-auto">
            <Doughnut data={pendingData} options={options} />
            <div className="absolute inset-0 flex justify-center items-center text-sm font-semibold">
                {pendiente}
            </div>
          </div>
          <p className="mt-2 font-semibold">Pendiente</p>
        </div>

        <div className="col-span-1 lg:col-span-1 text-center">
          <div className="relative h-12 w-12 m-auto">
            <Doughnut data={inactiveData} options={options} />
            <div className="absolute inset-0 flex justify-center items-center text-sm font-semibold">
              {inactivo}
            </div>
          </div>
          <p className="mt-2 font-semibold">Inactivo</p>
        </div>
      </div>

    
    </div>
  );
};

export default UserStatusChart;
