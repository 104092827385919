import React, { useState, useEffect } from "react";
import axios from "axios";
import { User, Plan } from "./UserManagement"; // Interface from UserManagement

interface EditUserModalProps {
  user: User | null;
  onClose: () => void;
  onSave: (updatedUser: User) => void;
}

const apiPort = process.env.REACT_APP_API_PORT;

const EditUserModal: React.FC<EditUserModalProps> = ({
  user,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState<User | null>(null);
  const [plans, setPlans] = useState<Plan[]>([]);

  useEffect(() => {
    if (user) {
      setFormData({
        ...user
       
      });
    }
    const fetchPlans = async () =>{
      try{
        const plansResponse = await axios.get(`${apiPort}/api/plans`);
        setPlans(plansResponse.data);
      }catch(error){
        console.error("Error fetching plans:", error);
        setPlans([]);
      }
    }

    fetchPlans();   

  }, [user]);

  if (!formData) return null;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e && e.target) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {    
    e.preventDefault();
    console.log(formData);
    onSave(formData); // Trigger onSave callback
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg w-1/3">
        <h2 className="text-xl mb-4">Edit User</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">País</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">Plan</label>
            <select
              name="currentPlan"
              value={formData.currentPlan}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            >
              {plans.map((plan) =>(
                <option value={plan._id} key={plan._id}>
                  {plan.name}
                </option>
              ))}             
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm">Role</label>
            <select
              name="role"
              value={formData.role}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            >
              <option value="Super Admin">Super Admin</option>
              <option value="Admin">Admin</option>
              <option value="User">User</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm">Status</label>
            <select
              name="status"
              value={formData.status}
              onChange={handleInputChange}
              className="border p-2 w-full rounded"
            >
              <option value="Active">Activo</option>
              <option value="Inactive">Inactivo</option>
              <option value="Pending">Pendiente</option>
            </select>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-purple-700 text-white px-4 py-2 rounded"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserModal;
