import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authenticateUser, isAuthenticated } from "./redux/slices/authSlice";
import { RootState } from "./redux/store";
import Signin from "./pages/Auth/Signin";
import Signup from "./pages/Auth/Signup";
import Dashboard from "./pages/Dashboard";
import EditProfile from "./pages/User/EditProfile";
import UserManagement from "./pages/User/UserManagement";
import ProtectedRoute from "./components/ProtectedRoute";
import ChangePassword from "./pages/Auth/ChangePassword";
import PlanManagement from "./pages/Plan/PlanManagement";
import CreatePlan from "./pages/Plan/CreatePlan";
import TemplatesPage from "./pages/Template/TemplatesPage";
import CreateTemplatePage from "./pages/Template/CreateTemplatePage";
import TextLayoutPage from "./pages/TextLayout/TextLayoutPage";
import CreateTextLayoutPage from "./pages/TextLayout/CreateTextLayoutPage";
import ShapeBankPage from "./pages/ShapeBank/ShapeBankPage";
import SoundsBankPage from "./pages/SoundBank/SoundsBankPage";
import ConfigurationPage from "./pages/Configuration/ConfigurationPage";
import PaymentPage from "./pages/Payment/PaymentPage";
import CreatePaymentPage from "./pages/Payment/CreatePayment";
import AdminConfiguration from "./pages/AdminConfiguration/AdminConfiguationPage";
import CreateUserPage from "./pages/User/CreateUser";

const App = () => {
  const dispatch = useDispatch();
  const isAuth = isAuthenticated()
  // const isAuthenticated = useSelector(
  //   (state: RootState) => state.auth.isAuthenticated
  // );
  
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(authenticateUser()); // Dispatch authenticateUser action if token exists
    }
  }, [dispatch]);
 
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={isAuth ? "/dashboard" : "/signin"} />}
        />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        {/* Protected Routes */}
        <Route element={<ProtectedRoute isAuthenticated={isAuth} />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/user/manage" element={<UserManagement />} />
          <Route path="/user/create" element={<CreateUserPage />} />
          <Route path="/plan-management" element={<PlanManagement />} />
          <Route path="/create-plan" element={<CreatePlan />} />
          <Route path="/editor/templates" element={<TemplatesPage />} />
          <Route
            path="/editor/create-template"
            element={<CreateTemplatePage />}
          />
          <Route path="/editor/text-layouts" element={<TextLayoutPage />} />
          <Route
            path="/editor/create-text-layout"
            element={<CreateTextLayoutPage />}
          />
          <Route path="/editor/shapes-bank" element={<ShapeBankPage />} />
          <Route path="/editor/sounds-bank" element={<SoundsBankPage />} />
          <Route path="/editor/settings" element={<ConfigurationPage />} />;
          <Route path="/payments" element={<PaymentPage />} />;
          <Route path="/create-payment" element={<CreatePaymentPage />} />;
          <Route path="/settings" element={<AdminConfiguration />} />;
          
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
