import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineFileDownload } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import axios from "axios";
import { Sound } from './SoundsBankPage';

interface EditSoundModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  sound: Sound | null; // Existing sound to edit
}

const apiPort = process.env.REACT_APP_API_PORT;


const EditSoundModal: React.FC<EditSoundModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  sound,
}) => {
  const [creditText, setCreditText] = useState<string>(sound?.name || ""); // Pre-populate with existing sound name
  const [licenseFile, setLicenseFile] = useState<File | null>(null); // New license file (optional)
  const [audioFile, setAudioFile] = useState<File | null>(null); // New audio file (optional)
  const [isPro, setIsPro] = useState<string>(sound?.planType || "Normal"); // Pre-populate with existing planType

  useEffect(() => {
    if (sound) {
      setCreditText(sound.name);
      setIsPro(sound.planType);
    }
  }, [sound]);

  // Dropzone for license file
  const {
    getRootProps: getLicenseRootProps,
    getInputProps: getLicenseInputProps,
  } = useDropzone({
    accept: {
      "text/plain": [".txt"],
    },
    onDrop: (acceptedFiles) => {
      setLicenseFile(acceptedFiles[0]);
    },
  });

  // Dropzone for audio file
  const { getRootProps: getAudioRootProps, getInputProps: getAudioInputProps } =
    useDropzone({
      accept: {
        "audio/mpeg": [".mp3"],
      },
      onDrop: (acceptedFiles) => {
        setAudioFile(acceptedFiles[0]);
      },
    });

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("name", creditText); // Update text credit
    if (licenseFile) {
      console.log(licenseFile.type);
      formData.append("txt", licenseFile); // Include new license file if uploaded
    }
    if (audioFile) {
      console.log(audioFile.type);
      formData.append("sound", audioFile); // Include new audio file if uploaded
    }
    formData.append("planType", isPro); // Update PRO status

    try {
      await axios.put(
        `${apiPort}/api/sounds/${sound?._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onSuccess();
      handleClearForm();
      onClose();
    } catch (error) {
      console.error("Error updating sound:", error);
    }
  };

  const handleClearForm = () => {
    setCreditText(sound?.name || "");
    setLicenseFile(null); // Clear license file
    setAudioFile(null); // Clear audio file
    setIsPro(sound?.planType || "Normal"); // Reset PRO status
  };

  if (!isOpen || !sound) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-lg h-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Editar audio</h2>
          <button onClick={onClose} className="text-2xl">
            &times;
          </button>
        </div>

        {/* Credit text input */}
        <div className="mb-4">
          <input
            type="text"
            className="border p-2 rounded w-full"
            placeholder="Créditos en texto"
            value={creditText}
            onChange={(e) => setCreditText(e.target.value)}
          />
        </div>

        {/* License upload area */}
        <div className="mb-4">
          <p className="text-gray-500">Cargar la licencia en TXT</p>
          <div
            {...getLicenseRootProps({
              className:
                "border-dashed border-2 border-gray-400 p-6 flex justify-center items-center rounded-lg cursor-pointer",
            })}
          >
            <input {...getLicenseInputProps()} />
            <MdOutlineFileDownload className="text-2xl" />
          </div>
          {licenseFile ? (
            <p className="mt-2 text-sm text-gray-600">{licenseFile.name}</p>
          ) : (
            <p className="mt-2 text-sm text-gray-600">
              Archivo existente: {sound.txtUrl}
            </p>
          )}
        </div>

        {/* Audio upload area */}
        <div className="mb-4">
          <p className="text-gray-500">Cargar Archivo mp3</p>
          <div
            {...getAudioRootProps({
              className:
                "border-dashed border-2 border-gray-400 p-6 flex justify-center items-center rounded-lg cursor-pointer",
            })}
          >
            <input {...getAudioInputProps()} />
            <MdOutlineFileDownload className="text-2xl" />
          </div>
          {audioFile ? (
            <p className="mt-2 text-sm text-gray-600">{audioFile.name}</p>
          ) : (
            <p className="mt-2 text-sm text-gray-600">
              Archivo existente: {sound.soundUrl}
            </p>
          )}
        </div>

        {/* PRO toggle */}
        <div className="flex items-center mb-4">
          <FaStar className="text-yellow-500" />
          <span className="ml-2">Es un sonido PRO</span>
          <div
            className={`relative inline-block ml-2 w-12 h-6 ${
              isPro === "Pro" ? "bg-yellow-500" : "bg-gray-300"
            } rounded-full cursor-pointer`}
            onClick={() => setIsPro(isPro === "Pro" ? "Normal" : "Pro")}
          >
            <span
              className={`absolute left-1 top-1 h-4 w-4 bg-white rounded-full transition-transform ${
                isPro === "Pro" ? "transform translate-x-6" : ""
              }`}
            ></span>
          </div>
        </div>

        {/* Action buttons */}
        <div className="flex justify-between mt-6">
          <button
            className="bg-gray-300 text-black px-4 py-2 rounded-full"
            onClick={onClose}
          >
            Cancelar
          </button>
          <button
            className="bg-purple-700 text-white px-4 py-2 rounded-full"
            onClick={handleSubmit}
            disabled={!creditText} // Ensure at least the credit text is provided
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSoundModal;
