import React, { useState, useEffect } from "react";
import { FaEllipsisV, FaSearch, FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import DashboardLayout from "../../layouts/DashboardLayout";
import { useNavigate } from "react-router-dom";

import EditUserModal from "./EditUserModal";

const apiPort = process.env.REACT_APP_API_PORT;

export interface User {
  _id: string;
  name: string;
  email: string;
  role: string;
  country: string;
  currentPlan: string;
  status: string;
  image: string;
  created: string;
  videos: number;
  videoLimit: number;
  storageUsed: number;
  storageLimit: number;
  authorizedUsers: number;
  authorizedLimit: number;
}

export interface Plan {
  _id: string;
  name: string;
  videosPerMonth: number;
  duration: string;
  languages: number;
  templates: boolean;
  monthlyCost: number;
  yearlyCost: number;
  status: string;
}

interface ApiResponse {
  users: User[];
  totalPages: number;
  currentPage: number;
}


const UserManagement = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [totalPages, setTotalPages] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 4;

  const [selectedUser, setSelectedUser] = useState<User | null>(null); // For modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plans, setPlans] = useState<Plan[]>([]); // State to hold plans

  const navigate = useNavigate();

  // Fetch users from the backend
  useEffect(() => {
    const fetchUsersAndPlans = async () => {
      try {
        const response = await axios.get<ApiResponse>(
          `${apiPort}/api/users/users`
        );
        setUsers(response.data.users);
        setFilteredUsers(response.data.users);
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);

        const plansResponse = await axios.get<Plan[]>(
          `${apiPort}/api/plans` // Adjust the endpoint as necessary
        );
        setPlans(plansResponse.data);
      } catch (error) {
        console.error("Error fetching users:", error);
        setUsers([]);
        setFilteredUsers([]);
        setPlans([]);
        setErrorMessage("Failed to fetch data. Please try again later.");
      }
    };
    fetchUsersAndPlans();
  }, []);

  const getPlanName = (currentPlanId: string) => {
    const plan = plans.find((plan) => plan._id === currentPlanId);
    return plan ? plan.name : "N/A"; // Return plan name or "N/A" if not found
  };

  // Handle search filtering
  useEffect(() => {
    let filtered = users.filter(
      (user) =>
        (user.name || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (user.country || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (user.role || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (user.status || "").toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (statusFilter) {
      filtered = filtered.filter(
        (user) =>
          (user.status || "").toLowerCase() === statusFilter.toLowerCase()
      );
    }

    // Handle sorting
    if (sortOption === "alphabetical") {
      filtered = filtered.sort((a, b) => a.name.localeCompare(b.name));
    }

    setFilteredUsers(filtered);
  }, [searchTerm, statusFilter, sortOption, users]);

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = Array.isArray(filteredUsers)
    ? filteredUsers.slice(indexOfFirstUser, indexOfLastUser)
    : [];

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // Edit user function
  const handleEdit = (user: User) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleSave = async (updatedUser: User) => {
    console.log(updatedUser);
    await axios.put(
      `${apiPort}/api/users/${updatedUser._id}`,
      updatedUser
    );
    // Refresh user list
    const response = await axios.get<ApiResponse>(
      `${apiPort}/api/users/users`
    );
    setUsers(response.data.users);
    setIsModalOpen(false);
  };

  // Delete user function
  const handleDelete = async (id: string) => {
    await axios.delete(`${apiPort}/api/users/${id}`);
    // Refresh user list
    const response = await axios.get<ApiResponse>(
      `${apiPort}/api/users/users`
    );
    setUsers(response.data.users);
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        {errorMessage && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <strong className="font-bold">Error:</strong>
            <span className="block sm:inline"> {errorMessage}</span>
          </div>
        )}
        <div className="flex justify-between items-center mb-4">
          <div></div>
          <div className="flex justify-end space-x-2">
            {/* Create Plan Button */}
            <button
              className="bg-dashcolor text-white p-2 rounded flex rounded-[25px] px-4 items-center"
              onClick={() => navigate("/user/create")}
            >
              Crear usuario
            </button>
          </div>
        </div>
        {/* Search and Filters */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center w-1/3 relative">
            <input
              type="text"
              placeholder="Buscar por nombre, cargo, país, plan o estado"
              className="border p-2 rounded w-full rounded-full"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="absolute top-0 right-0 ml-2 bg-dashcolor text-white p-2 rounded-[25px] flex items-center px-4">
              <FaSearch className="mr-2" /> Buscar
            </button>
          </div>
          <div className="flex justify-end w-2/3">
            {/* Filter Buttons */}
            <div className="ml-4">
              <button
                className={`ml-2 px-3 py-2 rounded border-full rounded-[25px] px-4 ${
                  statusFilter === ""
                    ? "bg-dashcolor text-white"
                    : "bg-gray-300"
                }`}
                onClick={() => setStatusFilter("")}
              >
                Todos
              </button>
              <button
                className={`ml-2 px-3 py-2 rounded rounded-[25px] px-4 ${
                  statusFilter === "active"
                    ? "bg-dashcolor text-white"
                    : "bg-gray-300"
                }`}
                onClick={() => setStatusFilter("active")}
              >
                Clientes con plan activo
              </button>
              <button
                className={`ml-2 px-3 py-2 rounded rounded-[25px] px-4 ${
                  statusFilter === "inactive"
                    ? "bg-dashcolor text-white"
                    : "bg-gray-300"
                }`}
                onClick={() => setStatusFilter("inactive")}
              >
                Clientes con plan inactivo
              </button>
            </div>

            {/* Sort Dropdown */}
            <div className="ml-4">
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                className="border p-2 rounded"
              >
                <option value="">Alfabético</option>
                <option value="alphabetical">Alfabético</option>
              </select>
            </div>
          </div>
        </div>

        {/* User Table */}
        <table className="min-w-full bg-white shadow-md rounded mb-4">
          <thead>
            <tr>
              <th className="text-left p-4">Nombre y cargo</th>
              <th className="text-left p-4">Fecha registro</th>
              <th className="text-left p-4">País</th>
              <th className="text-left p-4">Plan actual</th>
              <th className="text-left p-4">Videos</th>
              <th className="text-left p-4">Espacio</th>
              <th className="text-left p-4">Autorizados</th>
              <th className="text-left p-4">Estado</th>
              <th className="text-left p-4">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((user) => (
              <tr key={user._id} className="border-b">
                <td className="p-4 flex items-center">
                  <img
                    src={`${apiPort}${user.image}`}
                    alt={user.name}
                    className="w-8 h-8 rounded-full mr-4"
                  />
                  <div>
                    <p className="font-bold">{user.name}</p>
                    <p className="text-sm">{user.email}</p>
                  </div>
                </td>
                <td className="p-4 text-center">
                  {new Date(user.created).toLocaleDateString()}
                </td>
                <td className="p-4 text-center">{user.country || "N/A"}</td>
                <td className="p-4 text-center">
                  {getPlanName(user.currentPlan)}
                </td>
                <td className="p-4 text-center">{`${user.videos}/${user.videoLimit}`}</td>
                <td className="p-4 text-center">
                  <div className="flex items-center">
                    <div className="relative w-full h-4 bg-gray-200 rounded">
                      <div
                        className={`absolute h-full rounded ${
                          user.storageUsed < 50
                            ? "bg-green-500"
                            : user.storageUsed < 80
                            ? "bg-yellow-500"
                            : "bg-red-500"
                        }`}
                        style={{
                          width: `${
                            (user.storageUsed / user.storageLimit) * 100
                          }%`,
                        }}
                      ></div>
                    </div>
                    <span className="ml-2">
                      {Math.round((user.storageUsed / user.storageLimit) * 100)}
                      %
                    </span>
                  </div>
                </td>
                <td className="p-4 text-center">{`${user.authorizedUsers}/${user.authorizedLimit}`}</td>
                <td className="p-4">
                  <span
                    className={`px-2 py-1 rounded-full text-sm ${
                      user.status.toLowerCase() === "active"
                        ? "bg-green-600 text-white px-5 py-2"
                        : user.status.toLowerCase() === "inactive"
                        ? "bg-white text-orange-600 border border-orange-600 py-2 px-3"
                        : user.status.toLowerCase() === "pending"
                        ? "bg-white text-gray-600 border border-gray-600 py-2"
                        : ""
                    }`}
                  >
                    {user.status}
                  </span>
                </td>
                <td className="flex p-4">
                  <FaEdit
                    className="cursor-pointer bg-green mx-2"
                    onClick={() => handleEdit(user)}
                  />

                  <FaTrash
                    className="cursor-pointer bg-green"
                    onClick={() => handleDelete(user._id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="flex justify-between items-center">
          <span>
            Mostrando {indexOfFirstUser + 1} a {indexOfLastUser} de{" "}
            {filteredUsers.length} clientes
          </span>
          <div className="flex">
            {Array.from(
              { length: Math.ceil(filteredUsers.length / usersPerPage) },
              (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={`px-4 py-2 rounded ${
                    currentPage === index + 1
                      ? "bg-purple-700 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      </div>

      {/* Modal for editing user */}
      {isModalOpen && selectedUser && (
        <EditUserModal
          user={selectedUser}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSave}
        />
      )}
    </DashboardLayout>
  );
};

export default UserManagement;
