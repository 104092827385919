import React from "react";

interface UserCardProps {
  title: string;
  currency: string;
  amount: string;  
  icon: string;
  iconPercentage: string;
  iconPercentageColor: string;
  iconPercentageText: string;
  classProps: string;
}

const UserCard: React.FC<UserCardProps> = ({
  title,
  currency,
  amount,
  icon,
  iconPercentage,
  iconPercentageColor,
  iconPercentageText,
  classProps,
}) => {
  return (
    <div
      className={`p-6 bg-white rounded-lg shadow-md w-full  items-center relative`}
    >
        <div className="text-lg">
            {title}
        </div>
        <div className="flex mt-2 mb-6">
            <div className="text-xs mr-2 mt-2">
                {currency}
            </div>
            <div className="font-bold text-lg">
                {amount}
            </div>
        </div>

        <div className="flex absolute bottom-5 left-5">
            <div className="mt-3 mr-1">
                <img
                    src={icon}
                    alt="Logo"
                    className={`transition-all w-7 h-7 duration-300 overflow-hidden`}
                    />
            </div>
            <div>
                <div style={{ color: iconPercentageColor }}>
                    {iconPercentage}
                </div>
                <div className="text-gray-400 text-sm">
                    {iconPercentageText}
                </div>
            </div>

        </div>
      
    </div>
  );
};

export default UserCard;
