import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { updateUserProfile } from "../../redux/slices/authSlice";
import DashboardLayout from "../../layouts/DashboardLayout";

const apiPort = process.env.REACT_APP_API_PORT;

interface User {
  name?: string;
  email?: string;
  image?: string;
}

const EditProfile = () => {    
    const user = useSelector((state: RootState) => state.auth.user) as User | null;
    const [name, setName] = useState(user?.name || "");
    const [email, setEmail] = useState(user?.email || "");    
    const [image, setImage] = useState<File | null>(null);
    const [preview, setPreview] = useState(user?.image || ""); 
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImage(file);
            setPreview(URL.createObjectURL(file)); // Set the image preview
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      const token = localStorage.getItem("token"); // Assuming you store the JWT token in localStorage

      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        if (image) {
          formData.append("image", image); // Append image if there is one
        }

        const response = await axios.put(
          `${apiPort}/api/users/profile`, // Your API endpoint
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Dispatch the updated user data to Redux
        dispatch(updateUserProfile(response.data.user));

        alert("Profile updated successfully!");
        navigate("/dashboard");
      } catch (err) {
        console.error("Error updating profile", err);
        alert("Failed to update profile. Please try again.");
      }
    };

    return (
      <DashboardLayout>
        <h2 className="text-2xl font-bold mb-4">Edit Profile</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="p-2 border rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="p-2 border rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Profile Image
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="p-2 border rounded w-full"
            />
            {preview && (
              <div className="mt-4">
                <img
                  src={`${apiPort}${preview}`}
                  alt="Selected"
                  className="w-32 h-32 rounded-full"
                />
              </div>
            )}
          </div>
          <button type="submit" className="bg-blue-500 text-white p-2 rounded">
            Save Changes
          </button>
        </form>
      </DashboardLayout>
    );
};

export default EditProfile;
