import React, { useState, useEffect } from "react";

interface Payment {
    _id: string;
    userName: string;
    userEmail: string;
    currentPlan: string;
    renewalDate: string;
    daysInArrears: number;
    paymentStatus: string;
    planCost: string;
    userStatus: string;
    userId: string;
  }

  interface ViewPaymentModalProps {
    payment: Payment;
    isOpen: boolean;
    onClose: () => void;  
  }

  const ViewPaymentModal:  React.FC<ViewPaymentModalProps> = ({
    payment,
    isOpen,
    onClose,    
  }) => {   

    if (!isOpen) return null;

    return(
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-full max-w-lg h-auto max-h-[80%] overflow-y-auto">
                <h2 className="text-xl font-bold mb-4">View Payment</h2>

                <div className="mb-4">
                    <label className="block text-sm">Estado</label>
                    <div>
                        {payment.userName}
                    </div>
                    
                </div>
                <div className="flex justify-end space-x-2">
                    <button
                        type="button"
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>                    
                </div>
            </div>
                
        </div>
    )
  };

  export default ViewPaymentModal;
