import React from "react";

interface Template {
  id: string;
  name: string;
}

const templates: Template[] = [
  { id: "#0023", name: "Romantico" },
  { id: "#0024", name: "Romantico" },
  { id: "#0056", name: "Empresarial" },
  { id: "#0087", name: "Educación" },
  { id: "#0012", name: "Salud" },
];

const DashboardFooterCard = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold">Últimas plantillas creadas</h2>
        <div className="flex items-center space-x-4">
          <div className="flex space-x-2">
            {/* Pagination dots (static for now, can be dynamic if needed) */}
            <span className="w-2 h-2 rounded-full bg-purple-600"></span>
            <span className="w-2 h-2 rounded-full bg-gray-300"></span>
            <span className="w-2 h-2 rounded-full bg-gray-300"></span>
          </div>
          <a
            href="#"
            className="flex items-center text-purple-600 hover:text-purple-800"
          >
            Ver todas <span className="ml-2">→</span>
          </a>
        </div>
      </div>

      {/* Template cards */}
      <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
        {templates.map((template) => (
          <div
            key={template.id}
            className="border rounded-lg p-4 flex justify-between items-center text-center"
          >
            {/* Placeholder for image */}
            <div>
              <div className="w-16 h-16 bg-gray-200 rounded-lg"></div>
            </div>
            <div className="flex justify-end">
              <div>
                <h3 className="font-semibold text-gray-600">{template.id}</h3>
                <p className="text-purple-600">{template.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardFooterCard;
