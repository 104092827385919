import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
const apiPort = process.env.REACT_APP_API_PORT;

const CreateTextLayoutPage = () => {
  const [name, setName] = useState("");
  const [planType, setPlanType] = useState("Normal");
  const [status, setStatus] = useState("Active");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // JSON object instead of FormData
    const data = {
      name,
      planType,
      status,
      description,
    };

    try {
      await axios.post(`${apiPort}/api/text-layouts`, data, {
        headers: { "Content-Type": "application/json" },
      });
      navigate("/editor/text-layouts"); // Redirect to the layouts page after successful creation
    } catch (error) {
      console.error("Error creating text layout:", error);
      alert("Error creating text layout");
    }
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-4">Crear Diseño textos</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm">Nombre del Diseño textos</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border p-2 rounded w-full"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">Tipo de plan</label>
            <select
              value={planType}
              onChange={(e) => setPlanType(e.target.value)}
              className="border p-2 rounded w-full"
              required
            >
              <option value="Normal">Normal</option>
              <option value="Pro">Pro</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm">Estado</label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="border p-2 rounded w-full"
              required
            >
              <option value="Active">Activo</option>
              <option value="Inactive">Inactivo</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm">Descripción</label>
            <textarea
              className="border p-2 rounded w-full"
              value={description} // This binds the state
              onChange={(e) => setDescription(e.target.value)} // Update the state when text changes
              rows={4}
              placeholder="Type your message here..."
            />
          </div>

          <div className="flex justify-end space-x-2">
            <button
              type="button"
              className="bg-gray-500 text-white px-4 py-2 rounded"
              onClick={() => navigate("/text-layouts")}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="bg-dashcolor text-white px-4 py-2 rounded"
            >
              Crear Plantilla
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default CreateTextLayoutPage;
