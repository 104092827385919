import React, { useState } from "react";
import axios from "axios";
import { Template } from "./TemplatesPage";

interface EditTemplateModalProps {
  template: Template;
  onClose: () => void;
  onSave: (updatedTemplate: Template) => void;
}

const apiPort = process.env.REACT_APP_API_PORT;


const EditTemplateModal: React.FC<EditTemplateModalProps> = ({
  template,
  onClose,
  onSave,
}) => {
  const [formData, setFormData] = useState({
    name: template.name,
    category: template.category,
    planType: template.planType,
    status: template.status,
    title: template.title,
    description: template.description,
    previewImage: null as File | null,
    video: null as File | null,
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files?.[0] || null });
  };

  const handleSave = async () => {
    const updateData = new FormData();
    updateData.append("name", formData.name);
    updateData.append("category", formData.category);
    updateData.append("planType", formData.planType);
    updateData.append("status", formData.status);
    updateData.append("title", formData.title);
    updateData.append("description", formData.description);

    if (formData.previewImage) {
      updateData.append("previewImage", formData.previewImage);
    }
    if (formData.video) {
      updateData.append("video", formData.video);
    }

    try {
      const response = await axios.put(
        `${apiPort}/api/templates/${template._id}`,
        updateData
      );
      onSave(response.data);
      onClose();
    } catch (error) {
      console.error("Error updating template:", error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-lg h-auto max-h-[80%] overflow-y-auto">
        <h2 className="text-lg font-bold mb-4">Editar plantilla</h2>

        {/* Name Field */}
        <div className="mb-4">
          <label className="block text-sm">Nombre</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Nombre"
            className="mb-4 p-2 border rounded w-full"
          />
        </div>

        {/* Category Select Field */}
        <div className="mb-4">
          <label className="block text-sm">Categoría</label>
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="mb-4 p-2 border rounded w-full"
          >
            <option value="Educación">Educación</option>
            <option value="Empresarial">Empresarial</option>
            <option value="Tecnología">Tecnología</option>
            <option value="Salud">Salud</option>
          </select>
        </div>

        {/* Plan Type Select Field */}
        <div className="mb-4">
          <label className="block text-sm">Tipo de plan</label>
          <select
            name="planType"
            value={formData.planType}
            onChange={handleChange}
            className="mb-4 p-2 border rounded w-full"
          >
            <option value="Normal">Normal</option>
            <option value="Pro">Pro</option>
          </select>
        </div>

        {/* Status Select Field */}
        <div className="mb-4">
          <label className="block text-sm">Estado</label>
          <select
            name="status"
            value={formData.status}
            onChange={handleChange}
            className="mb-4 p-2 border rounded w-full"
          >
            <option value="Active">Activo</option>
            <option value="Inactive">Inactivo</option>
            <option value="Pending">Pendiente</option>
          </select>
        </div>

        {/* Title Field */}
        <div className="mb-4">
          <label className="block text-sm">Título</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="Título"
            className="mb-4 p-2 border rounded w-full"
          />
        </div>

        {/* Description Field */}
        <div className="mb-4">
          <label className="block text-sm">Descripción</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Descripción"
            className="mb-4 p-2 border rounded w-full"
          ></textarea>
        </div>

        {/* Preview Image Upload */}
        <div className="mb-4">
          <label className="block text-sm">Imagen de vista previa:</label>
          <input
            type="file"
            name="previewImage"
            onChange={handleFileChange}
            className="mb-4"
          />
        </div>

        {/* Video Upload */}
        <div className="mb-4">
          <label className="block text-sm">Video:</label>
          <input
            type="file"
            name="video"
            onChange={handleFileChange}
            className="mb-4"
          />
        </div>

        {/* Save and Cancel Buttons */}
        <div className="flex justify-end space-x-4">
          <button onClick={onClose} className="px-4 py-2 bg-gray-300 rounded">
            Cancelar
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditTemplateModal;
