import DashboardLayout from "../layouts/DashboardLayout";
import Card from '../components/Card';
import DiskUsage from "../components/DiskUsage";
import RecentActivity from "../components/RecentActivity";
import VideoHistoryChart from "../components/VideoHistoryChart";
import NewClients from "../components/NewClients";
import DashboardFooterCard from "../components/DashboardFooterCard";
import { FiUsers, FiFile, FiCheckCircle, FiFilm } from "react-icons/fi";

import ideaIcon from '../assets/icons/idea.svg';
import designIcon from '../assets/icons/ui_design.svg';
import teamworkIcon from '../assets/icons/teamwork.svg';
import startupIcon from '../assets/icons/startup.svg';

const Dashboard = () => {
  return (
    <DashboardLayout>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-3">
        <Card
          title="Planes activos"
          value="4"
          icon={<FiFile />}
          imgsrc={ideaIcon}
          bgColor="dashcard1"
          classProps="bg-dashcard1"
        />
        <Card
          title="Plantillas creadas"
          value="45"
          icon={<FiCheckCircle />}
          imgsrc={designIcon}
          bgColor="dashcard2"
          classProps="bg-dashcard2"
        />
        <Card
          title="Clientes activos"
          value="300"
          icon={<FiUsers />}
          imgsrc={teamworkIcon}
          bgColor="dashcard3"
          classProps="bg-dashcard3"
        />
        <Card
          title="Videos creados"
          value="4000"
          icon={<FiFilm />}
          imgsrc={startupIcon}
          bgColor="dashcard4"
          classProps="bg-dashcard4"
        />
      </div>

      {/* Disk Usage and Video History */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-3">
        <DiskUsage />
        <div className="col-span-1 lg:col-span-3">
          <VideoHistoryChart />
        </div>
      </div>

      {/* Recent Activity and New Clients */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-3">
        <RecentActivity />
        <div className="col-span-1 lg:col-span-3">
          <NewClients />
        </div>
      </div>

      {/* Dashboard Footer Card (Latest Templates) */}
      <DashboardFooterCard />
    </DashboardLayout>
  );
};

export default Dashboard;
