import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaSearch,
  FaEdit,
  FaTrash,
  FaBan,
  FaEye 
} from "react-icons/fa";

import DashboardLayout from "../../layouts/DashboardLayout";
import VideoHistoryChart from "./components/UserHistoryChart";
import eyeIcon from "../../assets/icons/eye.svg";
import conversationIcon from "../../assets/icons/conversation.svg";
import suitcaseIcon from "../../assets/icons/suitcase.svg";
import replyIcon from "../../assets/icons/reply.svg";
import UserCard from "./components/UserCard";
import UserStatusChart from "./components/UserStatusChart";

import ViewPaymentModal from "./ViewPaymentModal";

interface Payment {
  _id: string;
  userName: string;
  userEmail: string;
  currentPlan: string;
  renewalDate: string;
  daysInArrears: number;
  paymentStatus: string;
  planCost: string;
  userStatus: string;
  userId: string;
}
const apiPort = process.env.REACT_APP_API_PORT;

const PaymentPage = () => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [filteredPayments, setFilteredPayments] = useState<Payment[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get<Payment[]>(
          `${apiPort}/api/payment/getPayments`
        );
        setPayments(response.data);
        setFilteredPayments(response.data);
        
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };
    fetchPlans();
  }, []);

  // Filter plans by search term or status
  useEffect(() => {
    let filtered = payments.filter((payment) =>
      payment.userName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (statusFilter) {
      filtered = filtered.filter(
        (payment) => payment.paymentStatus === statusFilter
      );
    }
    setFilteredPayments(filtered);
  }, [searchTerm, statusFilter, payments]);

  const openViewModal = (payment: Payment) => {
    setSelectedPayment(payment); // Set the selected plan
    setIsModalOpen(true); // Open the modal
  };

  const deletePayment = async (id: string) => {
    try {
      await axios.delete(`${apiPort}/api/payment/${id}`);
      setPayments(payments.filter((payment) => payment._id !== id));
    } catch (error) {
      console.error("Error deleting plan:", error);
    }
  };

  const fetchPayments = async () => {
    try {
      const response = await axios.get(`${apiPort}/api/payment/getPayments`); 
      setPayments(response.data);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };

  const deactiveAccount = async (id: string) => {
    try {
      await axios.put(`${apiPort}/api/payment/deactivateUserAccount/${id}`);
      await fetchPayments();
    } catch (error) {
      console.error("Error deleting plan:", error);
    }
  };

  const viewPayment = async (id: string) => {
    try {
      await axios.get(`${apiPort}api/payment/viewPayment/${id}`);     
    } catch (error) {
      console.error("Error view plan:", error);
    }
  };

  return (
    <DashboardLayout>
      {/* Disk Usage and Video History */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-3">
        <div className="col-span-1 lg:col-span-2">
          <div className="mb-4">
            <VideoHistoryChart />
          </div>

          <div className="mb-4">
            <UserStatusChart
              description="Esta sección muestra el total de usuarios a la fecha según su estado."
              mora=""
              activos="500"
              pendiente="34"
              inactivo="12"
            />
          </div>
        </div>

        <div className="col-span-1 lg:col-span-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-3">
          <UserCard
            title="Recaudo actual"
            currency="USD"
            amount="999"
            icon={eyeIcon}
            iconPercentage="+24%"
            iconPercentageColor="#2bc155"
            iconPercentageText="Que el mes anterior"
            classProps=""
          />

          <UserCard
            title="Nuevos clientes"
            currency=""
            amount="28"
            icon={conversationIcon}
            iconPercentage=""
            iconPercentageColor=""
            iconPercentageText=""
            classProps=""
          />

          <UserCard
            title="Planes activos"
            currency=""
            amount="651"
            icon={suitcaseIcon}
            iconPercentage=""
            iconPercentageColor=""
            iconPercentageText=""
            classProps=""
          />

          <UserCard
            title="Videos generados"
            currency=""
            amount="2000"
            icon={replyIcon}
            iconPercentage=""
            iconPercentageColor=""
            iconPercentageText=""
            classProps=""
          />
        </div>
      </div>

      <div>
        <div className="p-6">
          {/* Search and Filter */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center w-1/3 relative">
              <input
                type="text"
                placeholder="Buscar por nombre por nombre del plan"
                className="border p-2 rounded w-full rounded-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button className="absolute top-0 right-0 ml-2 bg-dashcolor text-white p-2 rounded-[25px] rounded flex items-center">
                <FaSearch className="mr-2" /> Buscar
              </button>
            </div>
            <div className="flex justify-end w-1/2">
              {/* Filter Buttons */}
              <div className="flex space-x-2 mr-2">
                <button
                  onClick={() => setStatusFilter("")}
                  className={`p-2 rounded rounded-[25px] px-4  ${
                    statusFilter === ""
                      ? "bg-dashcolor text-white"
                      : "bg-planInactive text-gray-700"
                  }`}
                >
                  Todos
                </button>
                <button
                  onClick={() => setStatusFilter("Activo")}
                  className={`p-2 rounded rounded-[25px] px-4 ${
                    statusFilter === "Activo"
                      ? "bg-dashcolor text-white"
                      : "bg-planInactive text-gray-700"
                  }`}
                >
                  Plan Activo
                </button>
                <button
                  onClick={() => setStatusFilter("Inactivo")}
                  className={`p-2 rounded rounded-[25px] px-4 ${
                    statusFilter === "Inactivo"
                      ? "bg-dashcolor text-white"
                      : "bg-planInactive text-gray-700"
                  }`}
                >
                  Plan Inactivo
                </button>
              </div>
            </div>
          </div>
          <table className="min-w-full bg-white shadow-md rounded mb-4">
            <thead>
              <tr>
                <th className="text-left p-4">Cliente</th>
                <th className="text-left p-4">Plan actual</th>
                <th className="text-left p-4">Fecha renovación</th>
                <th className="text-left p-4">Días mora</th>
                <th className="text-left p-4">Estado de pago</th>
                <th className="text-left p-4">Detalles</th>
                <th className="text-left p-4">Estado cuenta</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredPayments.map((payment) => (
                <tr key={payment._id} className="border-b">
                  <td className="p-4 text-center">
                    <div>
                      {payment.userName}
                    </div>
                    <div>
                      Mail: {payment.userEmail}
                    </div>
                    
                  </td>
                  <td className="p-4 text-center">{payment.currentPlan}</td>
                  <td className="p-4 text-center">{payment.renewalDate}</td>
                  <td className="p-4 text-center">{payment.daysInArrears}</td>
                  <td className="p-4 text-center">
                    <span className={`px-2 py-1 rounded-full text-sm ${
                      payment.paymentStatus === "succeeded"
                        ? "bg-green-600 text-white px-5 py-2"                        
                        : "border border-gray-500 text-gray-500 py-2"
                    }`}>
                      {payment.paymentStatus === "succeeded" ? 'Sin deuda' : 'En mora'}
                    </span>                    
                  </td>
                  <td className="p-4 text-center">
                    <div>
                      <span className="font-bold">Pago: </span> 
                      {payment.planCost && (
                          <>
                              <span>{payment.planCost.split(" - ")[0]}</span> {/* Display the period */}                              
                          </>
                      )}
                    </div>

                    <div>
                      <span className="font-bold">Costo: </span> 
                      {payment.planCost && (
                          <>
                              <span> USD {payment.planCost.split(" - ")[1]}</span> {/* Display the price */}
                          </>
                      )}
                    </div>                   
                  </td>
                  <td className="p-4 text-center">
                  <span
                    className={`px-2 py-1 rounded-full text-sm ${
                      payment.userStatus === "Active"
                        ? "bg-green-600 text-white px-5 py-2"
                        : payment.userStatus === "Inactive"
                        ? "border border-orange-500 px-3 text-orange-500 py-2"
                        : "border border-gray-500 text-gray-500 py-2"
                    }`}
                  >
                    {payment.userStatus}
                  </span>
                </td>
                  <td className="flex p-4 text-center items-center">
                    <FaEye
                      className="cursor-pointer bg-green mx-2 mt-3"
                      onClick={() => openViewModal(payment)}
                    />
                    <FaBan
                      className="cursor-pointer bg-green mx-2 mt-3"
                      onClick={() => deactiveAccount(payment.userId)}
                    />
                    <FaTrash
                      onClick={() => deletePayment(payment._id)}
                      className="cursor-pointer bg-green mt-3"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {selectedPayment && (
        <ViewPaymentModal
          payment={selectedPayment}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </DashboardLayout>
  );
};

export default PaymentPage;
