import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdOutlineFileDownload } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import axios from "axios";

interface SoundUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const apiPort = process.env.REACT_APP_API_PORT;


const SoundUploadModal: React.FC<SoundUploadModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [creditText, setCreditText] = useState<string>(""); // Text input for credits
  const [licenseFile, setLicenseFile] = useState<File | null>(null); // License file in txt format
  const [audioFile, setAudioFile] = useState<File | null>(null); // Audio file in mp3 format
  const [isPro, setIsPro] = useState<string>("Normal"); // Toggle for PRO audio

  // Dropzone for license file
  const {
    getRootProps: getLicenseRootProps,
    getInputProps: getLicenseInputProps,
  } = useDropzone({
    accept: {
      "text/plain": [".txt"],
    },
    onDrop: (acceptedFiles) => {
      setLicenseFile(acceptedFiles[0]);
    },
  });

  // Dropzone for audio file
  const { getRootProps: getAudioRootProps, getInputProps: getAudioInputProps } =
    useDropzone({
      accept: {
        "audio/mpeg": [".mp3"], // Ensure 'audio/mpeg' MIME type is used for mp3
      },
      onDrop: (acceptedFiles) => {
        setAudioFile(acceptedFiles[0]);
      },
    });

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("name", creditText); // Include the text credit
    if (licenseFile) {
      console.log(licenseFile.type);
      formData.append("txt", licenseFile);
    }
    if (audioFile) {
      console.log(audioFile.type);
      formData.append("sound", audioFile);
    }
    formData.append("planType", isPro); // Add PRO status

    try {
      await axios.post(`${apiPort}/api/sounds`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      onSuccess();
      handleClearForm();
      onClose();
    } catch (error) {
      console.error("Error uploading sound:", error);
    }
  };

  const handleClearForm = () => {
    setCreditText(""); // Clear text input
    setLicenseFile(null); // Clear license file
    setAudioFile(null); // Clear audio file
    setIsPro("Normal"); // Reset PRO status
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-lg h-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold">Cargar nuevo audio</h2>
          <button onClick={onClose} className="text-2xl">
            &times;
          </button>
        </div>

        {/* Credit text input */}
        <div className="mb-4">
          <input
            type="text"
            className="border p-2 rounded w-full"
            placeholder="Créditos en texto"
            value={creditText}
            onChange={(e) => setCreditText(e.target.value)}
          />
        </div>

        {/* License upload area */}
        <div className="mb-4">
          <p className="text-gray-500">Cargar la licencia en TXT</p>
          <div
            {...getLicenseRootProps({
              className:
                "border-dashed border-2 border-gray-400 p-6 flex justify-center items-center rounded-lg cursor-pointer",
            })}
          >
            <input {...getLicenseInputProps()} />
            <MdOutlineFileDownload className="text-2xl" />
          </div>
          {licenseFile && (
            <p className="mt-2 text-sm text-gray-600">{licenseFile.name}</p>
          )}
        </div>

        {/* Audio upload area */}
        <div className="mb-4">
          <p className="text-gray-500">Cargar Archivo mp3</p>
          <div
            {...getAudioRootProps({
              className:
                "border-dashed border-2 border-gray-400 p-6 flex justify-center items-center rounded-lg cursor-pointer",
            })}
          >
            <input {...getAudioInputProps()} />
            <MdOutlineFileDownload className="text-2xl" />
          </div>
          {audioFile && (
            <p className="mt-2 text-sm text-gray-600">{audioFile.name}</p>
          )}
        </div>

        {/* PRO toggle */}
        <div className="flex items-center mb-4">
          <FaStar className="text-yellow-500" />
          <span className="ml-2">Es un sonido PRO</span>
          <div
            className={`relative inline-block ml-2 w-12 h-6 ${
              isPro === "Pro" ? "bg-yellow-500" : "bg-gray-300"
            } rounded-full cursor-pointer`}
            onClick={() => setIsPro(isPro === "Pro" ? "Normal" : "Pro")}
          >
            <span
              className={`absolute left-1 top-1 h-4 w-4 bg-white rounded-full transition-transform ${
                isPro === "Pro" ? "transform translate-x-6" : ""
              }`}
            ></span>
          </div>          
        </div>

        {/* Action buttons */}
        <div className="flex justify-between mt-6">
          <button
            className="bg-gray-300 text-black px-4 py-2 rounded-full"
            onClick={onClose}
          >
            Cancelar
          </button>
          <button
            className="bg-purple-700 text-white px-4 py-2 rounded-full"
            onClick={handleSubmit}
            disabled={!creditText || !audioFile || !licenseFile} // Ensure form is complete before enabling submit
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SoundUploadModal;
