import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaSearch, FaTrash } from "react-icons/fa";
import DashboardLayout from "../../layouts/DashboardLayout";
import {
  MdKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdViewList,
  MdViewModule,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ShapeUploadModal from "./ShapeUploadModal";

export interface Shape {
  _id: string;
  name: string;
  image: string;
}

interface ShapeResponse {
  shapes: Shape[];
  totalPages: number;
}

const apiPort = process.env.REACT_APP_API_PORT;


const ShapeBankPage = () => {
  const [shapes, setShapes] = useState<Shape[]>([]);
  const [filteredShapes, setFilteredShapes] = useState<Shape[]>([]); // For search filtering
  const [searchTerm, setSearchTerm] = useState("");
  const [viewType, setViewType] = useState<"grid" | "list">("grid");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch shapes from the backend
  const fetchShapes = async () => {
    try {
      const response = await axios.get<ShapeResponse>(
        `${apiPort}/api/shapes?page=${currentPage}&limit=5`
      );
      setShapes(response.data.shapes);
      setFilteredShapes(response.data.shapes);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching shapes:", error);
    }
  };

  useEffect(() => {
    fetchShapes();
  }, [currentPage]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSuccess = () => {
    fetchShapes(); // Refresh shape list after a successful upload
    setIsModalOpen(false); // Close modal
  };

  // Search functionality
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    if (term === "") {
      setFilteredShapes(shapes); // Reset filter if search is empty
    } else {
      const filtered = shapes.filter((shape) =>
        shape.name.toLowerCase().includes(term)
      );
      setFilteredShapes(filtered);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`${apiPort}/api/shapes/${id}`);
      setShapes(shapes.filter((t) => t._id !== id));
      setFilteredShapes(filteredShapes.filter((t) => t._id !== id));
    } catch (error) {
      console.error("Error deleting shape:", error);
    }
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        {/* Search, Filters, and View Toggles */}
        <div className="flex justify-between items-center mb-6 space-x-4">
          <div className="flex w-3/4 relative">
            <input
              type="text"
              placeholder="Buscar por nombre o descripción"
              className="border p-3 rounded w-full rounded-tr-[10px] rounded-br-[10px]"
              value={searchTerm}
              onChange={handleSearch}
            />
            <div className="absolute top-1 right-2 flex">
              <button className="bg-dashcolor text-white p-2 px-4 rounded rounded-[25px] flex items-center">
                <FaSearch className="mr-2" /> Buscar
              </button>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4 w-1/4">
            <button
              className="bg-dashcolor text-white p-2 px-4 rounded rounded-[25px] flex items-center mr-2"
              onClick={handleOpenModal}
            >
              Crear plantilla
            </button>
          </div>
        </div>

        <div className="flex">
          <div className="w-full">
            <div className="flex justify-end items-center mb-3">
              {/* View Toggle Buttons */}
              <button
                onClick={() => setViewType("grid")}
                className={`p-2 mr-2 ${
                  viewType === "grid"
                    ? "bg-dashcolor text-white"
                    : "bg-gray-300"
                } rounded-full`}
              >
                <MdViewModule />
              </button>
              <button
                onClick={() => setViewType("list")}
                className={`p-2 ${
                  viewType === "list"
                    ? "bg-dashcolor text-white"
                    : "bg-gray-300"
                } rounded-full`}
              >
                <MdViewList />
              </button>
            </div>

            {/* Templates View */}
            {viewType === "grid" ? (
              <div className="grid grid-cols-5 gap-5">
                {filteredShapes.length > 0 ? (
                  filteredShapes.map((shape) => (
                    <div
                      key={shape._id}
                      className="bg-white shadow-md rounded-lg p-4 cursor-pointer relative hover:shadow-lg transition"
                    >
                      <div className="items-center mb-2 text-center">
                        <img
                          src={`${apiPort}${shape.image}`}
                          alt="Preview"
                          className="w-full h-[120px] rounded"
                        />
                        <p>{shape.name}</p>
                        <button
                          className="bg-dashcolor text-white p-2 px-4 rounded rounded-[25px] items-center"
                          onClick={() => handleDelete(shape._id)}
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="col-span-4 text-center text-gray-500">
                    No se encontraron plantillas.
                  </p>
                )}
              </div>
            ) : (
              <div className="space-y-4">
                {filteredShapes.length > 0 ? (
                  filteredShapes.map((shape) => (
                    <div
                      key={shape._id}
                      className="bg-white shadow-md rounded-lg p-4 flex items-center justify-between hover:shadow-lg transition"
                    >
                      <div className="flex items-center space-x-4">
                        <img
                          src={`${apiPort}${shape.image}`}
                          alt="Preview"
                          className="w-20 h-auto rounded"
                        />
                        <div>
                          <p className="font-bold">{shape.name}</p>
                        </div>
                      </div>
                      <button
                        className="bg-dashcolor text-white p-2 px-4 rounded rounded-[25px] flex justity-end items-center"
                        onClick={() => handleDelete(shape._id)}
                      >
                        Eliminar
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="text-center text-gray-500">
                    No se encontraron plantillas.
                  </p>
                )}
              </div>
            )}

            {/* Pagination */}
            <div className="flex justify-between items-center mt-6">
              <span>Mostrando {filteredShapes.length} plantillas</span>
              <div className="flex space-x-2">
                <button
                  className="px-4 py-2 bg-white text-dashcolor rounded-full flex"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                >
                  <MdKeyboardDoubleArrowLeft className="mt-1 mr-1" /> Anterior
                </button>
                <span className="mt-2">
                  Página {currentPage} de {totalPages}
                </span>
                <button
                  className="px-4 py-2 bg-white text-dashcolor rounded-full flex"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  disabled={currentPage === totalPages}
                >
                  Siguiente{" "}
                  <MdOutlineKeyboardDoubleArrowRight className="mt-1 ml-1" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Shape Upload Modal */}
        <ShapeUploadModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSuccess={handleSuccess}
        />
      </div>
    </DashboardLayout>
  );
};

export default ShapeBankPage;
