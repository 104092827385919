import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const DiskUsage = () => {
  // Data for main disk usage
  const diskData = {
    labels: ["Used", "Free"],
    datasets: [
      {
        data: [96, 4], // 96% used, 4% free
        backgroundColor: ["#7C3AED", "#E5E7EB"], // Purple for used, gray for free
        hoverBackgroundColor: ["#A78BFA", "#D1D5DB"],
      },
    ],
  };

  // Data for CPU usage
  const cpuData = {
    labels: ["CPU Used", "CPU Free"],
    datasets: [
      {
        data: [66, 34], // 66% used, 34% free
        backgroundColor: ["#F97316", "#E5E7EB"], // Orange for used, gray for free
        hoverBackgroundColor: ["#FB923C", "#D1D5DB"],
      },
    ],
  };

  // Data for RAM usage
  const ramData = {
    labels: ["RAM Used", "RAM Free"],
    datasets: [
      {
        data: [31, 69], // 31% used, 69% free
        backgroundColor: ["#34D399", "#E5E7EB"], // Green for used, gray for free
        hoverBackgroundColor: ["#6EE7B7", "#D1D5DB"],
      },
    ],
  };

  const options = {
    cutout: "80%", // Size of the doughnut hole
    responsive: true,
    maintainAspectRatio: false, // Ensure we can control the aspect ratio manually
    plugins: {
      legend: {
        display: false, // Hide legend to show usage percentage
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
    },
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md text-center">
      {/* Main Disk Usage Chart */}
      <div className="relative h-40 mb-4">
        <Doughnut data={diskData} options={options} />
        <div className="absolute inset-0 flex justify-center items-center text-lg font-semibold">
          96%
        </div>
      </div>
      <h2 className="text-lg font-semibold">Uso del disco</h2>
      <p className="text-gray-500">Servidor local</p>

      {/* CPU and RAM Charts */}
      <div className="flex justify-around items-center mt-4 space-x-4">
        {/* CPU Usage */}
        <div className="text-center">
          <div className="relative h-24 w-24">
            <Doughnut data={cpuData} options={options} />
            <div className="absolute inset-0 flex justify-center items-center text-sm font-semibold">
              66%
            </div>
          </div>
          <p className="mt-2 font-semibold">CPU</p>
        </div>

        {/* RAM Usage */}
        <div className="text-center">
          <div className="relative h-24 w-24">
            <Doughnut data={ramData} options={options} />
            <div className="absolute inset-0 flex justify-center items-center text-sm font-semibold">
              31%
            </div>
          </div>
          <p className="mt-2 font-semibold">RAM</p>
        </div>
      </div>

      <p className="text-gray-400 mt-4 text-sm">Información de hace 1 minuto</p>
    </div>
  );
};

export default DiskUsage;
