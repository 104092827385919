import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";

const apiPort = process.env.REACT_APP_API_PORT;


const CreateTemplatePage = () => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [planType, setPlanType] = useState("Normal");
  const [status, setStatus] = useState("Active");
  const [previewImage, setPreviewImage] = useState<File | null>(null);
  const [video, setVideo] = useState<File | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("category", category);
    formData.append("planType", planType);
    formData.append("status", status);
    formData.append("title", title);
    formData.append("description", description);
    if (previewImage) formData.append("previewImage", previewImage); // Preview image
    if (video) formData.append("video", video); // Video file

    try {
      await axios.post(`${apiPort}/api/templates`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      navigate("/editor/templates"); // Redirect to templates page after successful creation
    } catch (error) {
      console.error("Error creating template:", error);
    }
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-4">Crear Plantilla</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm">Nombre del plan</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border p-2 rounded w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm">Categoría</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="border p-2 rounded w-full"
              required
            >
              <option value="">Seleccione una categoría</option>
              <option value="Educación">Educación</option>
              <option value="Empresarial">Empresarial</option>
              <option value="Tecnología">Tecnología</option>
              <option value="Salud">Salud</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm">Tipo de plan</label>
            <select
              value={planType}
              onChange={(e) => setPlanType(e.target.value)}
              className="border p-2 rounded w-full"
              required
            >
              <option value="Normal">Normal</option>
              <option value="Pro">Pro</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm">Estado</label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="border p-2 rounded w-full"
              required
            >
              <option value="Active">Activo</option>
              <option value="Inactive">Inactivo</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="border p-2 rounded w-full"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">Description</label>
            <textarea
              className="border p-2 rounded w-full"
              value={description} // This binds the state
              onChange={(e) => setDescription(e.target.value)} // Update the state when text changes
              rows={4}
              placeholder="Type your message here..."
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">
              Subir imagen de vista previa
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) =>
                setPreviewImage(e.target.files ? e.target.files[0] : null)
              }
              className="border p-2 rounded w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm">Subir video</label>
            <input
              type="file"
              accept="video/*"
              onChange={(e) =>
                setVideo(e.target.files ? e.target.files[0] : null)
              }
              className="border p-2 rounded w-full"
              required
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              className="bg-gray-500 text-white px-4 py-2 rounded"
              onClick={() => navigate("/templates")}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="bg-purple-700 text-white px-4 py-2 rounded"
            >
              Crear Plantilla
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default CreateTemplatePage;
