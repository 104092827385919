import React, { useState, useEffect } from "react";
import axios from "axios";
import { GetCountries, GetState, GetCity } from "react-country-state-city";

import { useNavigate } from "react-router-dom";

import DashboardLayout from "../../layouts/DashboardLayout";

const apiPort = process.env.REACT_APP_API_PORT;

export interface User {
  firstName: string;
  middleName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  phone: string;
  whatsapp: string;
  title: string;
  role: string;
  country: string;
  city: string;
  currentPlan: string;
  videos: number;
  videoLimit: number;
  storageUsed: number;
  storageLimit: number;
  authorizedUsers: number;
  authorizedLimit: number;
  status: string;
  created: Date;
  image: string;
}

export interface Plan {
  _id: string;
  name: string;
  monthlyCost: number;
  yearlyCost: number;
}

export interface CountryType {
  id: number;
  name: string;
}

export interface StateType {
  id: number;
  name: string;
}

export interface CityType {
  id: number;
  name: string;
}

const CreateUserPage = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [title, setTitle] = useState("");
  const [plans, setPlans] = useState<Plan[]>([]);

  const [countriesList, setCountriesList] = useState<CountryType[]>([]); // Specify the correct type
  const [stateList, setStateList] = useState<StateType[]>([]);
  const [cityList, setCityList] = useState<CityType[]>([]);

  const [selectedCountryId, setSelectedCountryId] = useState<number | null>(
    null
  );
  const [selectedStateId, setSelectedStateId] = useState<number | null>(null);
  const [selectedCityId, setSelectedCityId] = useState<number | null>(null);
  const [selectedPlanId, setSelectedPlanId] = useState<string>("");
  const [planPeriod, setPlanPeriod] = useState<string>("");
  const [active, setActive] = useState(false);

  const [description, setDescription] = useState("");

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const plansResponse = await axios.get<Plan[]>(
          `${apiPort}/api/plans` // Adjust the endpoint as necessary
        );
        setPlans(plansResponse.data);
      } catch (error) {
        console.error("Error fetching users:", error);

        setPlans([]);
        setErrorMessage("Failed to fetch data. Please try again later.");
      }

      const countries = await GetCountries();
      setCountriesList(countries);
    };
    fetchPlans();
  }, []);

  // Fetch states when a country is selected
  useEffect(() => {
    if (selectedCountryId) {
      GetState(selectedCountryId).then((states: StateType[]) => {
        setStateList(states);
        setCityList([]); // Reset cities when country changes
        setSelectedStateId(null);
      });
    }
  }, [selectedCountryId]);

  useEffect(() => {
    if (selectedStateId !== null) {
      GetCity(selectedCountryId, selectedStateId).then((cities: CityType[]) => {
        console.log(cities);
        setCityList(cities);
        // setSelectedCityId(null);
      });
    }
  }, [selectedStateId]);

  return (
    <DashboardLayout>
      <div className="container p-6 bg-white rounded ">
        {errorMessage && (
          <div className="text-red-500 mb-4">{errorMessage}</div>
        )}

        <div className="flex justify-between">
          <div>
            <h1 className="text-xl font-bold mb-4">Gestionar perfil</h1>
          </div>

          <div className="flex justify-end items-center">
            <label className="mr-2 text-gray-600">Estado</label>
            <div
              className={`relative inline-block mr-2 w-12 h-6 ${
                active ? "bg-dashcolor" : "bg-gray-300"
              } rounded-full cursor-pointer`}
              onClick={() => setActive(!active)}
            >
              <span
                className={`absolute left-1 top-1 h-4 w-4 bg-white rounded-full transition-transform ${
                  active ? "transform translate-x-6" : ""
                }`}
              ></span>
            </div>
            <button
              className="bg-gray-300 text-gray-600 px-4 py-2 rounded-full mr-4"
              onClick={() => navigate("/user/manage")}
            >
              Cancelar
            </button>

            <button
              type="submit"
              className="bg-dashcolor text-white px-4 py-2 rounded-full"
            >
              Guardar cambios
            </button>
          </div>
        </div>

        <div className="flex justify-between mb-4">
          <h3 style={{ textTransform: "uppercase" }}>GENERAL</h3>
          <div className="flex justify-end spliter"></div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-3">
          <div className="mb-4">
            <label className="block text-sm">* Primer nombre</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="border-b-2 p-2 rounded w-full"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">Segundo nombre</label>
            <input
              type="text"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
              className="border-b-2 p-2 rounded w-full"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">* Apellido(s)</label>
            <input
              type="text"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
              className="border-b-2 p-2 rounded w-full"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">* Nombre de usuario</label>
            <input
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="border-b-2 p-2 rounded w-full"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">* Contraseña</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border-b-2 p-2 rounded w-full"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">* Repetir contraseña</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="border-b-2 p-2 rounded w-full"
              required
            />
          </div>
        </div>

        <div className="flex justify-between mb-4">
          <h3 style={{ textTransform: "uppercase" }}>ADICIONAL</h3>
          <div className="flex justify-end spliter"></div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-3">
          <div className="mb-4">
            <label className="block text-sm">Número móvil</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="border-b-2 p-2 rounded w-full"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">Número móvil</label>
            <input
              type="text"
              value={whatsapp}
              onChange={(e) => setWhatsapp(e.target.value)}
              className="border-b-2 p-2 rounded w-full"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">* Correo electrónico</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border-b-2 p-2 rounded w-full"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm">Ciudad</label>
            <select
              className="border-b-2 p-2 rounded w-full"
              onChange={(e) => {
                const countryId = parseInt(e.target.value, 10);
                setSelectedCountryId(countryId);
                setSelectedStateId(null); // Reset state
                setSelectedCityId(null); // Reset city
              }}
              value={selectedCountryId ?? ""}
            >
              <option value="">Select Country</option>
              {countriesList.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm">State</label>
            <select
              className="border-b-2 p-2 rounded w-full"
              onChange={(e) => {
                const stateId = parseInt(e.target.value, 10);
                console.log(stateId);
                setSelectedStateId(stateId);
                setSelectedCityId(null); // Reset city
              }}
              value={selectedStateId ?? ""}
              disabled={!selectedCountryId}
            >
              <option value="">Select State</option>
              {stateList.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm">City</label>
            <select
              className="border-b-2 p-2 rounded w-full"
              onChange={(e) => setSelectedCityId(parseInt(e.target.value, 10))}
              value={selectedCityId ?? ""}
              disabled={!selectedStateId}
            >
              <option value="">Select City</option>
              {cityList.map((city) => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex justify-between mb-4">
          <h3 className="uppercase">acerca de mí</h3>
          <div className="flex justify-end spliter"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 mb-3">
          <div className="mb-4">
            <label className="block text-sm">Descripción breve</label>
            <textarea
              name=""
              rows={6}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border-b-2 p-2 rounded w-full"
              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
    que laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta su"
            ></textarea>
          </div>
        </div>

        <div className="flex justify-between mb-4">
          <h3 className="line-clamp-1 uppercase">Suscripción</h3>
          <div className="flex justify-end spliter"></div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-3">
          <div className="mb-4">
            <label className="block text-sm">Seleccionar tipo de plan</label>

            <select
              value={selectedPlanId}
              onChange={(e) => setSelectedPlanId(e.target.value)}
              className="border-b-2 p-2 rounded w-full"
            >
              <option value="">Select Plan</option>
              {plans.map((plan) => (
                <option key={plan._id} value={plan._id}>
                  {plan.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm">Periodo de pago</label>

            <select
              value={planPeriod}
              onChange={(e) => setPlanPeriod(e.target.value)} // Convert string to boolean
              className="border-b-2 p-2 rounded w-full"
            >
              <option value="true">Mensual</option>
              <option value="false">Anual</option>
            </select>
          </div>
        </div>

        <div className="mb-4">
          Todos los campos marcados con (*) son obligatorio.
        </div>

        <div className="text-center">
          <button
            onClick={() => navigate("/user/manage")}
            className="bg-gray-300 text-gray-600 px-4 py-2 rounded-full mr-4"
          >
            Cancelar
          </button>

          <button
            type="submit"
            className="bg-dashcolor text-white px-4 py-2 rounded-full"
          >
            Guardar cambios
          </button>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CreateUserPage;
