import React, { useState, useEffect } from "react";

interface Plan {
  _id: string;
  name: string;
  videosPerMonth: number;
  duration: string;
  languages: number;
  templates: boolean;
  monthlyCost: number;
  yearlyCost: number;
  status: string;
}

interface EditPlanModalProps {
  plan: Plan;
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedPlan: Plan) => void;
}

const EditPlanModal: React.FC<EditPlanModalProps> = ({
  plan,
  isOpen,
  onClose,
  onSave,
}) => {
  const [name, setName] = useState(plan.name);
  const [videosPerMonth, setVideosPerMonth] = useState(plan.videosPerMonth);
  const [duration, setDuration] = useState(plan.duration);
  const [languages, setLanguages] = useState(plan.languages);
  const [templates, setTemplates] = useState(plan.templates);
  const [monthlyCost, setMonthlyCost] = useState(plan.monthlyCost);
  const [yearlyCost, setYearlyCost] = useState(plan.yearlyCost);
  const [status, setStatus] = useState(plan.status);

  // When plan prop changes, reset the modal fields
  useEffect(() => {
    setName(plan.name);
    setVideosPerMonth(plan.videosPerMonth);
    setDuration(plan.duration);
    setLanguages(plan.languages);
    setTemplates(plan.templates);
    setMonthlyCost(plan.monthlyCost);
    setYearlyCost(plan.yearlyCost);
    setStatus(plan.status);
  }, [plan]);

  const handleSave = () => {
    const updatedPlan = {
      ...plan,
      name,
      videosPerMonth,
      duration,
      languages,
      templates,
      monthlyCost,
      yearlyCost,
      status,
    };
    onSave(updatedPlan);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-lg h-auto max-h-[80%] overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">Editar Plan</h2>
        <form>
          <div className="mb-4">
            <label className="block text-sm">Nombre del plan</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm">Videos mes</label>
            <input
              type="number"
              value={videosPerMonth}
              onChange={(e) => setVideosPerMonth(Number(e.target.value))}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm">Duración</label>
            <input
              type="text"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm">Idiomas</label>
            <input
              type="number"
              value={languages}
              onChange={(e) => setLanguages(Number(e.target.value))}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm">Plantillas</label>
            <select
              value={templates ? "yes" : "no"}
              onChange={(e) => setTemplates(e.target.value === "yes")}
              className="border p-2 rounded w-full"
            >
              <option value="yes">Sí</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm">Costos Mensuales (USD)</label>
            <input
              type="number"
              value={monthlyCost}
              onChange={(e) => setMonthlyCost(Number(e.target.value))}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm">Costos Anuales (USD)</label>
            <input
              type="number"
              value={yearlyCost}
              onChange={(e) => setYearlyCost(Number(e.target.value))}
              className="border p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm">Estado</label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="border p-2 rounded w-full"
            >
              <option value="Activo">Activo</option>
              <option value="Inactivo">Inactivo</option>
              <option value="Pendiente">Pendiente</option>
            </select>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              className="bg-gray-500 text-white px-4 py-2 rounded"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="bg-dashcolor text-white px-4 py-2 rounded"
              onClick={handleSave}
            >
              Guardar Cambios
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPlanModal;
