import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isAuthenticated: false,
  user: null,
  status: "idle",
  error: null as string | null,
};

const apiPort = process.env.REACT_APP_API_PORT;
// Utility to check if user is authenticated
export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return !!token; // returns true if token exists, false otherwise
};


export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (credentials: { email: string; password: string }) => {
    const response = await axios.post(
      `${apiPort}/api/auth/signin`,
      credentials
    );
    localStorage.setItem("token", response.data.token); // Store token in localStorage  
    localStorage.setItem("user-name", response.data.user.name);
    localStorage.setItem("user-image", response.data.user.image);
    localStorage.setItem("user-role", response.data.user.role);
    return response.data; // Return token and user info
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticateUser: (state) => {
      const token = localStorage.getItem("token");
      if (token) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("token"); // Remove token on logout
      localStorage.removeItem("user-name");
      localStorage.removeItem("user-image");
      localStorage.removeItem("user-role");
    },
    updateUserProfile(state, action) {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.user; // Save user info
        state.isAuthenticated = true;
        state.status = "succeeded";
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || "Login failed";
      });
  },
});

export const { authenticateUser, logout, updateUserProfile } =
  authSlice.actions;
export default authSlice.reducer;
