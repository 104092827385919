import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";


const DashboardLayout = ({ children }: { children: React.ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true); // Sidebar state

  // Function to toggle the sidebar visibility
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (    
      <div className="flex">
        {/* Sidebar */}
        <Sidebar isOpen={sidebarOpen} />

        <div className="flex-1">
          {/* Navbar with a function to toggle the sidebar */}
          <Navbar toggleSidebar={toggleSidebar} />

          {/* Main content area */}
          <main className="p-4 bg-gray-100 min-h-screen">{children}</main>
        </div>
      </div>    
  );
};

export default DashboardLayout;
