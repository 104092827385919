import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaStar, FaUser, FaSearch, FaFilter } from "react-icons/fa";
import DashboardLayout from "../../layouts/DashboardLayout";
import { GiMaterialsScience } from "react-icons/gi";
import {
  MdKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdViewList,
  MdViewModule,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import EditTemplateModal from "./EditTextLayoutModal";
import prevImage from '../../assets/img/textlayout.png';
import { dateFormat } from "../../utils/helper";

export interface TextLayout {
  _id: string;
  name: string;
  planType: string;
  status: string;
  description: string;
  createdAt: string;
}

interface TextLayoutResponse {
  textLayouts: TextLayout[];
  totalPages: number;
}

const apiPort = process.env.REACT_APP_API_PORT;

const TextLayoutPage = () => {
  const navigate = useNavigate();
  const [textLayouts, setTextLayouts] = useState<TextLayout[]>([]); // Initialize with an empty array
  const [selectedTextLayout, setSelectedTextLayout] =
    useState<TextLayout | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [planTypeFilter, setPlanTypeFilter] = useState(""); // Normal or Pro
  const [viewType, setViewType] = useState<"grid" | "list">("grid"); // View type toggle (grid/list)
  const [showProTextLayouts, setShowProTextLayouts] = useState(false); // Toggle for showing Pro templates
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Fetch templates from the backend
  useEffect(() => {
    const fetchTextLayouts = async () => {
      try {
        const response = await axios.get<TextLayoutResponse>(
          `${apiPort}/api/text-layouts?page=${currentPage}&limit=5`
        );
        setTextLayouts(response.data.textLayouts);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching text layouts:", error);
      }
    };
    fetchTextLayouts();
  }, [currentPage]);

  // Filter templates based on plan type and category
  const filteredTextLayouts = textLayouts.filter((textLayout) => {
    const isPro = showProTextLayouts
      ? textLayout.planType === "Pro"
      : textLayout.planType === "Normal" || textLayout.planType === "Pro";
    return isPro;
  });

  // Handle template preview
  const handleTextLayout = (textlayout: TextLayout) => {
    setSelectedTextLayout(textlayout);
  };

  const handleDuplicate = async () => {
    if (selectedTextLayout) {
      try {
        const response = await axios.post(
          `${apiPort}/api/text-layouts/${selectedTextLayout._id}/duplicate`
        );
        setTextLayouts([...textLayouts, response.data]);
      } catch (error) {
        console.error("Error duplicating text layout:", error);
      }
    }
  };

  const handleDelete = async () => {
    if (selectedTextLayout) {
      try {
        await axios.delete(
          `${apiPort}/api/text-layouts/${selectedTextLayout._id}`
        );
        setTextLayouts(
          textLayouts.filter((t) => t._id !== selectedTextLayout._id)
        );
        setSelectedTextLayout(null);
      } catch (error) {
        console.error("Error deleting text layout:", error);
      }
    }
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        {/* Search, Filters, and View Toggles */}
        <div className="flex justify-between items-center mb-6 space-x-4">
          <div className="flex w-2/4 relative">
            <input
              type="text"
              placeholder="Buscar por código"
              className="border p-3 rounded w-full rounded-[20px]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute top-1 right-2 flex">
              <button className="bg-dashcolor text-white p-2 px-4 rounded rounded-[25px] flex items-center">
                <FaSearch className="mr-2" /> Buscar
              </button>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4 w-1/4">
            <button
              className="bg-dashcolor text-white p-2 px-4 rounded rounded-[25px] flex items-center mr-2"
              onClick={() => navigate("/editor/create-text-layout")}
            >
              Crear diseño
            </button>
          </div>
        </div>

        <div className="flex">
          <div className="w-3/4">
            <div className="flex justify-end items-center mb-3">
              <div className="w-7 h-7 p-1 border border-rounded rounded-full bg-yellow-400 mr-2">
                <FaStar className="text-white" />
              </div>
              <label className="mr-2 text-gray-600">Diseños PRO</label>
              {/* Custom Toggle Button */}
              <div
                className={`relative inline-block mr-2 w-12 h-6 ${
                  showProTextLayouts ? "bg-yellow-500" : "bg-gray-300"
                } rounded-full cursor-pointer`}
                onClick={() => setShowProTextLayouts(!showProTextLayouts)}
              >
                <span
                  className={`absolute left-1 top-1 h-4 w-4 bg-white rounded-full transition-transform ${
                    showProTextLayouts ? "transform translate-x-6" : ""
                  }`}
                ></span>
              </div>
              {/* View Toggle Buttons */}
              <button
                onClick={() => setViewType("grid")}
                className={`p-2 mr-2 ${
                  viewType === "grid"
                    ? "bg-dashcolor text-white"
                    : "bg-gray-300"
                } rounded-full`}
              >
                <MdViewModule />
              </button>
              <button
                onClick={() => setViewType("list")}
                className={`p-2 ${
                  viewType === "list"
                    ? "bg-dashcolor text-white"
                    : "bg-gray-300"
                } rounded-full`}
              >
                <MdViewList />
              </button>
            </div>
            {/* Templates View */}
            {viewType === "grid" ? (
              <div className="grid grid-cols-4 gap-4">
                {filteredTextLayouts.length > 0 ? (
                  filteredTextLayouts.map((textlayout) => (
                    <div
                      key={textlayout._id}
                      className="bg-white shadow-md rounded-lg p-4 cursor-pointer relative hover:shadow-lg transition"
                      onClick={() => handleTextLayout(textlayout)}
                    >
                      <div className="items-center mb-2 text-center">
                        <div className="absolute top-0 right-2">
                          {textlayout.planType === "Pro" ? (
                            <div className="w-7 h-7 p-1 border border-rounded rounded-full bg-yellow-400">
                              <FaStar className="text-white" />
                            </div>
                          ) : null}
                        </div>
                        <img
                          src={prevImage}
                          alt="Preview"
                          className="w-full h-[120px] rounded"
                        />
                        <p>{textlayout.name}</p>
                      </div>

                      <div className="absolute bottom-4 right-4">
                        {textlayout.status === "Active" ? (
                          <div className="w-3 h-3 rounded-full bg-dashcolor"></div>
                        ) : (
                          <div className="w-3 h-3 rounded-full bg-gray-600"></div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="col-span-4 text-center text-gray-500">
                    No se encontraron plantillas.
                  </p>
                )}
              </div>
            ) : (
              <div className="space-y-4">
                {filteredTextLayouts.length > 0 ? (
                  filteredTextLayouts.map((textlayout) => (
                    <div
                      key={textlayout._id}
                      className="bg-white shadow-md rounded-lg p-4 flex items-center justify-between hover:shadow-lg transition"
                      onClick={() => handleTextLayout(textlayout)}
                    >
                      <div className="flex items-center space-x-4">
                        <img
                          src={prevImage}
                          alt="Preview"
                          className="w-20 h-auto rounded"
                        />
                        <div>
                          <p className="font-bold">{textlayout.name}</p>
                        </div>
                      </div>
                      <div>
                        <p
                          className={`text-sm ${
                            textlayout.status === "Active"
                              ? "text-green-500"
                              : "text-gray-500"
                          }`}
                        >
                          {textlayout.status}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center text-gray-500">
                    No se encontraron plantillas.
                  </p>
                )}
              </div>
            )}

            {/* Pagination */}
            <div className="flex justify-between items-center mt-6">
              <span>
                Mostrando {filteredTextLayouts.length} de {textLayouts.length}{" "}
                plantillas
              </span>
              <div className="flex space-x-2">
                <button
                  className="px-4 py-2 bg-white text-dashcolor rounded-full flex"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                >
                  <MdKeyboardDoubleArrowLeft className="mt-1 mr-1" /> Anterior
                </button>
                <span className="mt-2">
                  Página {currentPage} de {totalPages}
                </span>
                <button
                  className="px-4 py-2 bg-white text-dashcolor rounded-full flex"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                >
                  Siguiente{" "}
                  <MdOutlineKeyboardDoubleArrowRight className="mt-1 ml-1" />
                </button>
              </div>
            </div>
          </div>

          {/* Right side: Preview panel */}
          <div className="w-1/4 bg-white p-4 rounded-lg ml-2">
            <h2 className="text-lg font-bold mb-4">Vista previa</h2>
            {selectedTextLayout ? (
              <div>
                <div className="mt-4 relative">
                  <div className="rounded-[10px] p-4 bg-gray-300">
                    <div className="font-bold ">Descripción</div>
                    {selectedTextLayout.description}
                  </div>
                  <div className="absolute bottom-[-10px] right-0">
                    {selectedTextLayout.planType === "Pro" ? (
                      <div className="w-7 h-7 p-1 border border-rounded rounded-full bg-yellow-400">
                        <FaStar className="text-white" />
                      </div>
                    ) : null}
                  </div>
                </div>
                <p className="font-bold text-xl text-center">
                  {selectedTextLayout.name}
                </p>

                <hr className="mt-5 mb-2" />

                <span className=" mr-2">Diseño creado el</span> 
                <span>{dateFormat(selectedTextLayout.createdAt)}</span>

                <div className="flex justify-between mt-4">
                  <button
                    onClick={() => setIsEditModalOpen(true)}
                    className="px-4 py-2 bg-dashcolor text-white rounded-full mr-1"
                  >
                    Editar
                  </button>
                  <button
                    onClick={handleDuplicate}
                    className="px-4 py-2 bg-blue-500 text-white rounded-full mr-1"
                  >
                    Duplicar
                  </button>
                  <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-gray-500 text-white rounded-full"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            ) : (
              <p className="text-gray-400 text-center">
                Haz clic en una plantilla para ver más detalles.
              </p>
            )}
          </div>
        </div>
        {/* Edit Modal */}
        {isEditModalOpen && selectedTextLayout && (
          <EditTemplateModal
            textlayout={selectedTextLayout}
            onClose={() => setIsEditModalOpen(false)}
            onSave={(updatedTextLayout) => {
              setTextLayouts((prevLayouts) =>
                prevLayouts.map((t) =>
                  t._id === updatedTextLayout._id ? updatedTextLayout : t
                )
              );
              setSelectedTextLayout(updatedTextLayout);
            }}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default TextLayoutPage;
