import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaStar, FaUser, FaSearch, FaFilter } from "react-icons/fa";
import DashboardLayout from "../../layouts/DashboardLayout";
import { GiMaterialsScience } from "react-icons/gi";
import {
  MdKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdViewList,
  MdViewModule,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import EditTemplateModal from "./EditTemplateModal";

export interface Template {
  _id: string;
  name: string;
  category: string;
  planType: string;
  status: string;
  previewUrl: string;
  videoUrl: string;
  title: string;
  description: string;
}

interface TemplateResponse {
  templates: Template[];
  totalPages: number;
}

const apiPort = process.env.REACT_APP_API_PORT;

const TemplatesPage = () => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [planTypeFilter, setPlanTypeFilter] = useState(""); // Normal or Pro
  const [categoryFilter, setCategoryFilter] = useState("");
  const [viewType, setViewType] = useState<"grid" | "list">("grid"); // View type toggle (grid/list)
  const [showProTemplates, setShowProTemplates] = useState(false); // Toggle for showing Pro templates
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Fetch templates from the backend
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get<TemplateResponse>(
          `${apiPort}/api/templates?page=${currentPage}&limit=5`
        );
        setTemplates(response.data.templates);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    fetchTemplates();
  }, [currentPage]);

  // Filter templates based on plan type and category
  const filteredTemplates = templates.filter((template) => {
    const isPro = showProTemplates
      ? template.planType === "Pro"
      : template.planType === "Normal" || template.planType === "Pro";
    return (
      isPro &&
      template.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (categoryFilter === "" || template.category === categoryFilter)
    );
  });

  // Handle template preview
  const handleTemplateClick = (template: Template) => {
    setSelectedTemplate(template);
  };

  const handleDuplicate = async () => {
    if (selectedTemplate) {
      try {
        const response = await axios.post(
          `${apiPort}/api/templates/${selectedTemplate._id}/duplicate`
        );
        setTemplates([...templates, response.data]);
      } catch (error) {
        console.error("Error duplicating template:", error);
      }
    }
  };

  const handleDelete = async () => {
    if (selectedTemplate) {
      try {
        await axios.delete(
          `${apiPort}/api/templates/${selectedTemplate._id}`
        );
        setTemplates(templates.filter((t) => t._id !== selectedTemplate._id));
        setSelectedTemplate(null);
      } catch (error) {
        console.error("Error deleting template:", error);
      }
    }
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        {/* Search, Filters, and View Toggles */}
        <div className="flex justify-between items-center mb-6 space-x-4">
          <div className="flex w-3/4 relative">
            <select
              className="border p-2 rounded text-gray-500 w-1/4 rounded-tl-[10px] rounded-bl-[10px]"
              onChange={(e) => setCategoryFilter(e.target.value)}
            >
              <option value="">En todas las categorías</option>
              <option value="Educación">Educación</option>
              <option value="Empresarial">Empresarial</option>
              <option value="Tecnología">Tecnología</option>
              <option value="Salud">Salud</option>
            </select>
            <input
              type="text"
              placeholder="Buscar por nombre o descripción"
              className="border p-3 rounded w-full rounded-tr-[10px] rounded-br-[10px]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute top-1 right-2 flex">
              <button className="bg-gray-300 p-2 px-4 rounded rounded-[25px] flex items-center mr-2">
                <FaFilter className="mr-2" /> Filtros
              </button>
              <button className="bg-dashcolor text-white p-2 px-4 rounded rounded-[25px] flex items-center">
                <FaSearch className="mr-2" /> Buscar
              </button>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4 w-1/4">
            <button
              className="bg-dashcolor text-white p-2 px-4 rounded rounded-[25px] flex items-center mr-2"
              onClick={() => navigate("/editor/create-template")}
            >
              Crear plantilla
            </button>
            <button className="bg-orange-600 text-white p-2 px-4 rounded rounded-[25px] flex items-center">
              <GiMaterialsScience />
              Configurar
            </button>
          </div>
        </div>

        <div className="flex">
          <div className="w-3/4">
            <div className="flex justify-end items-center mb-3">
              <div className="w-7 h-7 p-1 border border-rounded rounded-full bg-yellow-400 mr-2">
                <FaStar className="text-white" />
              </div>
              <label className="mr-2 text-gray-600">Plantillas PRO</label>
              {/* Custom Toggle Button */}
              <div
                className={`relative inline-block mr-2 w-12 h-6 ${
                  showProTemplates ? "bg-yellow-500" : "bg-gray-300"
                } rounded-full cursor-pointer`}
                onClick={() => setShowProTemplates(!showProTemplates)}
              >
                <span
                  className={`absolute left-1 top-1 h-4 w-4 bg-white rounded-full transition-transform ${
                    showProTemplates ? "transform translate-x-6" : ""
                  }`}
                ></span>
              </div>
              {/* View Toggle Buttons */}
              <button
                onClick={() => setViewType("grid")}
                className={`p-2 mr-2 ${
                  viewType === "grid"
                    ? "bg-dashcolor text-white"
                    : "bg-gray-300"
                } rounded-full`}
              >
                <MdViewModule />
              </button>
              <button
                onClick={() => setViewType("list")}
                className={`p-2 ${
                  viewType === "list"
                    ? "bg-dashcolor text-white"
                    : "bg-gray-300"
                } rounded-full`}
              >
                <MdViewList />
              </button>
            </div>
            {/* Templates View */}
            {viewType === "grid" ? (
              <div className="grid grid-cols-4 gap-4">
                {filteredTemplates.length > 0 ? (
                  filteredTemplates.map((template) => (
                    <div
                      key={template._id}
                      className="bg-white shadow-md rounded-lg p-4 cursor-pointer relative hover:shadow-lg transition"
                      onClick={() => handleTemplateClick(template)}
                    >
                      <div className="items-center mb-2 text-center">
                        <div className="absolute top-0 right-2">
                          {template.planType === "Pro" ? (
                            <div className="w-7 h-7 p-1 border border-rounded rounded-full bg-yellow-400">
                              <FaStar className="text-white" />
                            </div>
                          ) : null}
                        </div>
                        <img
                          src={`${apiPort}${template.previewUrl}`}
                          alt="Preview"
                          className="w-full h-[120px] rounded"
                        />
                        <p>{template.name}</p>
                      </div>
                      <p className="text-sm text-gray-500 mb-5 text-center">
                        {template.category}
                      </p>
                      <div className="absolute bottom-4 right-4">
                        {template.status === "Active" ? (
                          <div className="w-3 h-3 rounded-full bg-dashcolor"></div>
                        ) : (
                          <div className="w-3 h-3 rounded-full bg-gray-600"></div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="col-span-4 text-center text-gray-500">
                    No se encontraron plantillas.
                  </p>
                )}
              </div>
            ) : (
              <div className="space-y-4">
                {filteredTemplates.length > 0 ? (
                  filteredTemplates.map((template) => (
                    <div
                      key={template._id}
                      className="bg-white shadow-md rounded-lg p-4 flex items-center justify-between hover:shadow-lg transition"
                      onClick={() => handleTemplateClick(template)}
                    >
                      <div className="flex items-center space-x-4">
                        <img
                          src={`${apiPort}${template.previewUrl}`}
                          alt="Preview"
                          className="w-20 h-auto rounded"
                        />
                        <div>
                          <p className="font-bold">{template.name}</p>
                          <p className="text-sm text-gray-500 text-center">
                            {template.category}
                          </p>
                        </div>
                      </div>
                      <div>
                        <p
                          className={`text-sm ${
                            template.status === "Active"
                              ? "text-green-500"
                              : "text-gray-500"
                          }`}
                        >
                          {template.status}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center text-gray-500">
                    No se encontraron plantillas.
                  </p>
                )}
              </div>
            )}

            {/* Pagination */}
            <div className="flex justify-between items-center mt-6">
              <span>
                Mostrando {filteredTemplates.length} de {templates.length}{" "}
                plantillas
              </span>
              <div className="flex space-x-2">
                <button
                  className="px-4 py-2 bg-white text-dashcolor rounded-full flex"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                >
                  <MdKeyboardDoubleArrowLeft className="mt-1 mr-1" /> Anterior
                </button>
                <span className="mt-2">
                  Página {currentPage} de {totalPages}
                </span>
                <button
                  className="px-4 py-2 bg-white text-dashcolor rounded-full flex"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                >
                  Siguiente{" "}
                  <MdOutlineKeyboardDoubleArrowRight className="mt-1 ml-1" />
                </button>
              </div>
            </div>
          </div>

          {/* Right side: Preview panel */}
          <div className="w-1/4 bg-white p-4 rounded-lg ml-2">
            <h2 className="text-lg font-bold mb-4">Vista previa</h2>
            {selectedTemplate ? (
              <div>
                <div className="mt-4 relative">
                  <video
                    className="w-full max-w-3xl rounded-lg shadow-lg"
                    controls
                  >
                    <source
                      src={`${apiPort}${selectedTemplate.videoUrl}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                  <div className="absolute bottom-[-10px] right-0">
                    {selectedTemplate.planType === "Pro" ? (
                      <div className="w-7 h-7 p-1 border border-rounded rounded-full bg-yellow-400">
                        <FaStar className="text-white" />
                      </div>
                    ) : null}
                  </div>
                </div>
                <p className="font-bold text-xl text-center">
                  {selectedTemplate.name}
                </p>
                <p className="text-sm text-gray-500 mb-2 text-center">
                  Categoría: {selectedTemplate.category}
                </p>

                <div className="flex">
                  <div className="bg-dashcolor p-2 w-8 h-8 rounded-full mt-2">
                    <FaUser className="text-white" />
                  </div>
                  <div className="ml-2">
                    <div className="font-bold">Créditos</div>
                    <div>{selectedTemplate.title}</div>
                  </div>
                </div>

                <div className="font-bold mt-5">Descripción</div>
                <div className="mt-4">{selectedTemplate.description}</div>

                <div className="flex justify-between mt-4">
                  <button
                    onClick={() => setIsEditModalOpen(true)}
                    className="px-4 py-2 bg-dashcolor text-white rounded-full mr-1"
                  >
                    Editar
                  </button>
                  <button
                    onClick={handleDuplicate}
                    className="px-4 py-2 bg-blue-500 text-white rounded-full mr-1"
                  >
                    Duplicar
                  </button>
                  <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-gray-500 text-white rounded-full"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            ) : (
              <p className="text-gray-400 text-center">
                Haz clic en una plantilla para ver más detalles.
              </p>
            )}
          </div>
        </div>
        {/* Edit Modal */}
        {isEditModalOpen && selectedTemplate && (
          <EditTemplateModal
            template={selectedTemplate}
            onClose={() => setIsEditModalOpen(false)}
            onSave={(updatedTemplate) => {
              setTemplates((templates) =>
                templates.map((t) =>
                  t._id === updatedTemplate._id ? updatedTemplate : t
                )
              );
              setSelectedTemplate(updatedTemplate);
            }}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default TemplatesPage;
