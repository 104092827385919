import { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { logout } from "../redux/slices/authSlice";
import { useNavigate, Link } from "react-router-dom";
import { FaBarsStaggered } from "react-icons/fa6";
import {
  FaChevronDown,
  FaChevronUp, 
} from "react-icons/fa"; // Import relevant icons

interface User {
  name?: string;
  image?: string;
  role?: string;
}

const apiPort = process.env.REACT_APP_API_PORT;

const Navbar = ({ toggleSidebar }: { toggleSidebar: () => void }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const userName = localStorage.getItem('user-name') || null;
  const userImage = localStorage.getItem("user-image") || null;
  const userRole = localStorage.getItem("user-role") || null;

  const selectedItem = useSelector(
    (state: RootState) => state.sidebar.selectedItem
  );

  const handleLogout = () => {
    dispatch(logout());
    navigate("/signin");
  };

  return (
    <nav className="bg-gray-100 shadow-md p-4 flex justify-between items-center relative">
      {/* Left purple area with rounded corner */}
      <div className="absolute top-0 left-0 h-full w-10 bg-dashcolor z-0"></div>
      <div className="absolute top-0 left-0 h-full w-10 bg-gray-100 rounded-tl-full z-1"></div>

      <div className="flex items-center z-10 relative">
        {/* Sidebar Toggle Button */}
        <FaBarsStaggered
          className="text-xl cursor-pointer ml-4"
          onClick={toggleSidebar}
        />
        <h1 className="text-lg font-bold ml-4 text-black">{selectedItem}</h1>
      </div>

      <div className="relative z-10">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <div>
            <img
              src={
                userImage
                  ? `${apiPort}${userImage}`
                  : "/path/to/default-avatar.png"
              }
              alt="User Avatar"
              className="w-10 h-10 rounded-full"
            />
          </div>
          <div>
            <div className="ml-2 flex">
              {userName}
              {isDropdownOpen ? (
                <FaChevronUp className="mt-1 ml-1" />
              ) : (
                <FaChevronDown className="mt-1 ml-1" />
              )}
            </div>

            <div className="ml-2">{userRole}</div>
          </div>
        </div>

        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg">
            <ul className="text-left">
              <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">
                <Link to="/edit-profile">Edit Profile</Link>
              </li>
              <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">
                <Link to="/change-password">Change Password</Link>
              </li>
              <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">
                <Link to="/privacy">Privacy</Link>
              </li>
              <li className="px-4 py-2 hover:bg-gray-200 cursor-pointer">
                <Link to="/help-center">Help Center</Link>
              </li>
              <li
                className="px-4 py-2 hover:bg-gray-200 cursor-pointer text-red-600"
                onClick={handleLogout}
              >
                Sign Out
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
