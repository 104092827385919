import React, { useState, useEffect } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import axios from "axios";
import voice_icon from "../../assets/icons/voice.svg";
import video_icon from "../../assets/icons/video.svg";
import aws_s3_icon from "../../assets/icons/aws_s3.svg";
import img_icon from "../../assets/icons/image.svg";
import gif_icon from "../../assets/icons/gif.svg";
import category_icon from "../../assets/icons/category.svg";
import CreateCategoryModal from "./CreateCategoryModal";

interface Category {
  _id: string;
  name: string;
  usage: number;
}


const ConfigurationPage = () => {
  const [settings, setSettings] = useState({
    voiceServer_lovoApiKey: "",
    voiceServer_customApiKey: "",
    voiceServer_customUrl: "",
    voiceServer_isLovoEnabled: false,
    voiceServer_isCustomEnabled: false,
    ffmpegServer_apiKey: "",
    ffmpegServer_apiUrl: "",
    ffmpegServer_isFfmpegEnabled: false,
    awsServer_bucketName: "",
    awsServer_bucketRegion: "",
    awsServer_accessId: "",
    awsServer_secretKey: "",
    awsServer_isAwsEnabled: false,
    unsplashServer_urlApi: "",
    unsplashServer_appId: "",
    unsplashServer_apiKey: "",
    unsplashServer_acessKey: "",
    unsplashServer_secretKey: "",
    unsplashServer_isUnsplashEnabled: false,
    pexcelsServer_urlApiImage: "",
    pexcelsServer_urlApiVideo: "",
    pexcelsServer_apiKey: "",
    pexcelsServer_isPexelsEnabled: false,
    pixabayServer_urlApiImage: "",
    pixabayServer_urlApiVideo: "",
    pixabayServer_apiKey: "",
    pixabayServer_isPixabayEnabled: false,
    coverServer_urlApi: "",
    coverServer_id: "",
    coverServer_apiKey: "",
    coverServer_isCoverEnabled: false,
    stickerServer_urlApi: "",
    stickerServer_id: "",
    stickerServer_isStickerEnabled: false,
  });

  const apiPort = process.env.REACT_APP_API_PORT;


  const [showSuccess, setShowSuccess] = useState(false); // Success notification state
  const [showError, setShowError] = useState(false); // Error notification state
  const [categories, setCategories] = useState<Category[]>([]);
  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${apiPort}/api/config`);
        if (response.data) {
          setSettings(response.data);
        } else {
          console.warn("No settings found.");
        }

        const responseCategories = await axios.get(
          `${apiPort}/api/categories`
        );
        if (responseCategories.data) {
          setCategories(responseCategories.data);
        } else {
          console.warn("No categories found.");
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof typeof settings
  ) => {
    setSettings({ ...settings, [field]: e.target.value });
  };

  const handleToggleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof typeof settings
  ) => {
    setSettings({ ...settings, [field]: e.target.checked });
  }; 
  
  const deleteCategory = async (id: string) => {
    try {
      await axios.delete(`${apiPort}/api/categories/${id}`);
      setCategories(categories.filter((category) => category._id !== id));
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  const handleCategoryCreated = (newCategory: Category) => {
    setCategories([...categories, newCategory]); // Add new category to state
    setIsCreateCategoryModalOpen(false); // Close the modal after success
  };

  const handleSave = async () => {
    try {
      await axios.post(`${apiPort}/api/config`, settings);
      setShowSuccess(true); // Show success notification
      setShowError(false); // Hide error notification if it's visible
      setTimeout(() => setShowSuccess(false), 3000); // Hide after 3 seconds
    } catch (error) {
      console.error("Error saving settings:", error);
      setShowError(true); // Show error notification
      setShowSuccess(false); // Hide success notification if it's visible
      setTimeout(() => setShowError(false), 3000); // Hide after 3 seconds
    }
  };

  return (
    <DashboardLayout>
      {showSuccess && (
        <div className="fixed top-5 right-0 bg-green-500 text-white p-3 rounded-lg shadow-md">
          Cambios guardados correctamente
        </div>
      )}

      {/* Error Notification */}
      {showError && (
        <div className="fixed top-5 right-0 bg-red-500 text-white p-3 rounded-lg shadow-md">
          Error al guardar cambios
        </div>
      )}

      <div className="flex">
        <div className="w-1/2 m-1">

          <div className="p-6">
            {/* Voice Server Section */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15 mr-1">
                  <img src={voice_icon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">Servidor de voces</h3>
                  <div className="text-gray-600 mb-4">
                    Desde esta sección se podrá configurar el servidor de voces
                    que estará activo para los usuarios de la plataforma.
                  </div>
                </div>
              </div>

              {/* LOVO Server Configuration */}
              <div className="mb-6">
                <h4 className="font-semibold">Servidor LOVO</h4>
                <p className="text-gray-600">
                  Agrega a continuación el API KEY.
                </p>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mt-2 mb-4 rounded"
                      placeholder="Aquí el API..."
                      value={settings.voiceServer_lovoApiKey}
                      onChange={(e) =>
                        handleInputChange(e, "voiceServer_lovoApiKey")
                      }
                      disabled={!settings.voiceServer_isLovoEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6 mt-4">
                    {/* Toggle Switch for LOVO */}
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={settings.voiceServer_isLovoEnabled}
                        onChange={(e) =>
                          handleToggleChange(e, "voiceServer_isLovoEnabled")
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>

              {/* Custom Server Configuration */}
              <div className="mb-6">
                <h4 className="font-semibold">Servidor propio</h4>
                <p className="text-gray-600">
                  Agrega a continuación los campos de URL y API KEY.
                </p>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mt-2 mb-4 rounded"
                      placeholder="Aquí URL API"
                      value={settings.voiceServer_customUrl}
                      onChange={(e) =>
                        handleInputChange(e, "voiceServer_customUrl")
                      }
                      disabled={!settings.voiceServer_isCustomEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6 mt-4">
                    {/* Toggle Switch for Custom Server */}
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={settings.voiceServer_isCustomEnabled}
                        onChange={(e) =>
                          handleToggleChange(e, "voiceServer_isCustomEnabled")
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="Aquí el API..."
                      value={settings.voiceServer_customApiKey}
                      onChange={(e) =>
                        handleInputChange(e, "voiceServer_customApiKey")
                      }
                      disabled={!settings.voiceServer_isCustomEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>
              </div>

              <div className="text-center">
                {/* Save Button */}
                <button
                  className="bg-dashcolor text-white px-6 py-2 rounded-full"
                  onClick={handleSave}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>

          <div className="p-6 mt-1">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15  mr-1">
                  <img src={category_icon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">
                    Lista de categorías plantillas
                  </h3>
                  <div className="text-gray-600 mb-4">
                    Desde esta sección se podrá gestionar las categorías de la
                    sección de plantillas de la plataforma.
                  </div>
                </div>
              </div>
              <div >
                <div className="category_table">
                  <table className="min-w-full bg-white shadow-md rounded mb-4">
                    <thead>
                      <tr>
                        <th>Nombre de la categoría</th>
                        <th>Plantillas asociadas</th>
                        <th>Herramientas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.map((category: any) => (
                        <tr key={category._id} className="border">
                          <td className="text-center px-2 py-2">{category.name}</td>
                          <td className="text-center px-2 py-2">{category.usage}</td>
                          <td className="text-center px-2 py-2">
                            <button
                              onClick={() => deleteCategory(category._id)}
                              className="bg-gray-100 text-gray-800  px-4 py-2 rounded-full"
                            >
                              Eliminar
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="text-center">
                  {/* Save Button */}
                  <button
                    className="bg-dashcolor text-white px-6 py-2 rounded-full"
                    onClick={() => setIsCreateCategoryModalOpen(true)}
                  >
                    Crear categoría
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="p-6 mt-1">
            {/* Voice Server Section */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15  mr-1">
                  <img src={img_icon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">
                    Banco de imágenes PEXELS
                  </h3>
                  <div className="text-gray-600 mb-4">
                    Desde esta sección se podrá configurar el servicio por medio
                    de API de la plataforma de PEXELS
                  </div>
                </div>
              </div>

              {/* Custom Server Configuration */}
              <div className="mb-6">
                <h4 className="font-semibold">Configurar PEXELS</h4>
                <p className="text-gray-600">
                  Agrega a continuación los campos solicitados
                </p>

                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mt-2 mb-4 rounded"
                      placeholder="URL API imágenes"
                      value={settings.pexcelsServer_urlApiImage}
                      onChange={(e) =>
                        handleInputChange(e, "pexcelsServer_urlApiImage")
                      }
                      disabled={!settings.pexcelsServer_isPexelsEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6 mt-4">
                    {/* Toggle Switch for Custom Server */}
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={settings.pexcelsServer_isPexelsEnabled}
                        onChange={(e) =>
                          handleToggleChange(e, "pexcelsServer_isPexelsEnabled")
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="URL API videos"
                      value={settings.pexcelsServer_urlApiVideo}
                      onChange={(e) =>
                        handleInputChange(e, "pexcelsServer_urlApiVideo")
                      }
                      disabled={!settings.pexcelsServer_isPexelsEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>

                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="API KEY"
                      value={settings.pexcelsServer_apiKey}
                      onChange={(e) =>
                        handleInputChange(e, "pexcelsServer_apiKey")
                      }
                      disabled={!settings.pexcelsServer_isPexelsEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>
              </div>

              <div className="text-center">
                {/* Save Button */}
                <button
                  className="bg-dashcolor text-white px-6 py-2 rounded-full"
                  onClick={handleSave}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>

          <div className="p-6 mt-1">
            {/* Voice Server Section */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15  mr-1">
                  <img src={img_icon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">
                    Banco de imágenes PIXABAY
                  </h3>
                  <div className="text-gray-600 mb-4">
                    Desde esta sección se podrá configurar el servicio por medio
                    de API de la plataforma de PIXABAY
                  </div>
                </div>
              </div>

              {/* Custom Server Configuration */}
              <div className="mb-6">
                <h4 className="font-semibold">Configurar PIXABAY</h4>
                <p className="text-gray-600">
                  Agrega a continuación los campos solicitados
                </p>

                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mt-2 mb-4 rounded"
                      placeholder="URL API imágenes"
                      value={settings.pixabayServer_urlApiImage}
                      onChange={(e) =>
                        handleInputChange(e, "pixabayServer_urlApiImage")
                      }
                      disabled={!settings.pixabayServer_isPixabayEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6 mt-4">
                    {/* Toggle Switch for Custom Server */}
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={settings.pixabayServer_isPixabayEnabled}
                        onChange={(e) =>
                          handleToggleChange(
                            e,
                            "pixabayServer_isPixabayEnabled"
                          )
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="URL API videos"
                      value={settings.pixabayServer_urlApiVideo}
                      onChange={(e) =>
                        handleInputChange(e, "pixabayServer_urlApiVideo")
                      }
                      disabled={!settings.pixabayServer_isPixabayEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>

                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="API KEY"
                      value={settings.pixabayServer_apiKey}
                      onChange={(e) =>
                        handleInputChange(e, "pixabayServer_apiKey")
                      }
                      disabled={!settings.pixabayServer_isPixabayEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>
              </div>

              <div className="text-center">
                {/* Save Button */}
                <button
                  className="bg-dashcolor text-white px-6 py-2 rounded-full"
                  onClick={handleSave}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>

          <div className="p-6 mt-1">
            {/* Voice Server Section */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15 mr-1">
                  <img src={video_icon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">
                    Banco de videos COVERR
                  </h3>
                  <div className="text-gray-600 mb-4">
                    Desde esta sección se podrá configurar el servicio por medio
                    de API de la plataforma de PIXABAY
                  </div>
                </div>
              </div>

              {/* Custom Server Configuration */}
              <div className="mb-6">
                <h4 className="font-semibold">Configurar COVERR</h4>
                <p className="text-gray-600">
                  Agrega a continuación los campos solicitados
                </p>

                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mt-2 mb-4 rounded"
                      placeholder="URL API"
                      value={settings.coverServer_urlApi}
                      onChange={(e) =>
                        handleInputChange(e, "coverServer_urlApi")
                      }
                      disabled={!settings.coverServer_isCoverEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6 mt-4">
                    {/* Toggle Switch for Custom Server */}
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={settings.coverServer_isCoverEnabled}
                        onChange={(e) =>
                          handleToggleChange(e, "coverServer_isCoverEnabled")
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="ID"
                      value={settings.coverServer_id}
                      onChange={(e) => handleInputChange(e, "coverServer_id")}
                      disabled={!settings.coverServer_isCoverEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>

                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="API KEY"
                      value={settings.coverServer_apiKey}
                      onChange={(e) =>
                        handleInputChange(e, "coverServer_apiKey")
                      }
                      disabled={!settings.coverServer_isCoverEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>
              </div>

              <div className="text-center">
                {/* Save Button */}
                <button
                  className="bg-dashcolor text-white px-6 py-2 rounded-full"
                  onClick={handleSave}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/2 m-1">
          <div className="p-6">
            {/* Voice Server Section */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15  mr-1">
                  <img src={video_icon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">Servidor FFMPEG</h3>
                  <div className="text-gray-600 mb-4">
                    Desde esta sección se podrá configurar el servidor de FFMPEG
                    para generar los videos de la plataforma.
                  </div>
                </div>
              </div>

              {/* Custom Server Configuration */}
              <div className="mb-6">
                <h4 className="font-semibold">Servidor</h4>
                <p className="text-gray-600">
                  Agrega a continuación los campos de URL y API KEY.
                </p>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mt-2 mb-4 rounded"
                      placeholder="Aquí el API..."
                      value={settings.ffmpegServer_apiKey}
                      onChange={(e) =>
                        handleInputChange(e, "ffmpegServer_apiKey")
                      }
                      disabled={!settings.ffmpegServer_isFfmpegEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6 mt-4">
                    {/* Toggle Switch for Custom Server */}
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={settings.ffmpegServer_isFfmpegEnabled}
                        onChange={(e) =>
                          handleToggleChange(e, "ffmpegServer_isFfmpegEnabled")
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="Aquí  URL API"
                      value={settings.ffmpegServer_apiUrl}
                      onChange={(e) =>
                        handleInputChange(e, "ffmpegServer_apiUrl")
                      }
                      disabled={!settings.ffmpegServer_isFfmpegEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>
              </div>

              <div className="text-center">
                {/* Save Button */}
                <button
                  className="bg-dashcolor text-white px-6 py-2 rounded-full"
                  onClick={handleSave}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>

          <div className="p-6 mt-1">
            {/* Voice Server Section */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15 mr-1">
                  <img src={aws_s3_icon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">Servidor AWS S3</h3>
                  <div className="text-gray-600 mb-4">
                    Desde esta sección se podrá configurar el servidor de S3, si
                    no esta configurado, se guardara localmente.
                  </div>
                </div>
              </div>

              {/* Custom Server Configuration */}
              <div className="mb-6">
                <h4 className="font-semibold">Configurar S3</h4>
                <p className="text-gray-600">
                  Agrega a continuación los campos solicitados
                </p>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mt-2 mb-4 rounded"
                      placeholder="Nombre del bucket"
                      value={settings.awsServer_bucketName}
                      onChange={(e) =>
                        handleInputChange(e, "awsServer_bucketName")
                      }
                      disabled={!settings.awsServer_isAwsEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6 mt-4">
                    {/* Toggle Switch for Custom Server */}
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={settings.awsServer_isAwsEnabled}
                        onChange={(e) =>
                          handleToggleChange(e, "awsServer_isAwsEnabled")
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="Region del bucket"
                      value={settings.awsServer_bucketRegion}
                      onChange={(e) =>
                        handleInputChange(e, "awsServer_bucketRegion")
                      }
                      disabled={!settings.awsServer_isAwsEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="Access Key ID"
                      value={settings.awsServer_accessId}
                      onChange={(e) =>
                        handleInputChange(e, "awsServer_accessId")
                      }
                      disabled={!settings.awsServer_isAwsEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="Secret Access Key"
                      value={settings.awsServer_secretKey}
                      onChange={(e) =>
                        handleInputChange(e, "awsServer_secretKey")
                      }
                      disabled={!settings.awsServer_isAwsEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>
              </div>

              <div className="text-center">
                {/* Save Button */}
                <button
                  className="bg-dashcolor text-white px-6 py-2 rounded-full"
                  onClick={handleSave}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>

          <div className="p-6 mt-1">
            {/* Voice Server Section */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15 mr-1">
                  <img src={img_icon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">
                    Banco de imágenes UNSPLASH
                  </h3>
                  <div className="text-gray-600 mb-4">
                    Desde esta sección se podrá configurar el servicio por medio
                    de API de la plataforma de UNSPLASH
                  </div>
                </div>
              </div>

              {/* Custom Server Configuration */}
              <div className="mb-6">
                <h4 className="font-semibold">Configurar UNSPLASH</h4>
                <p className="text-gray-600">
                  Agrega a continuación los campos solicitados
                </p>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mt-2 mb-4 rounded"
                      placeholder="URL API"
                      value={settings.unsplashServer_urlApi}
                      onChange={(e) =>
                        handleInputChange(e, "unsplashServer_urlApi")
                      }
                      disabled={!settings.unsplashServer_isUnsplashEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6 mt-4">
                    {/* Toggle Switch for Custom Server */}
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={settings.unsplashServer_isUnsplashEnabled}
                        onChange={(e) =>
                          handleToggleChange(
                            e,
                            "unsplashServer_isUnsplashEnabled"
                          )
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="Application ID"
                      value={settings.unsplashServer_appId}
                      onChange={(e) =>
                        handleInputChange(e, "unsplashServer_appId")
                      }
                      disabled={!settings.unsplashServer_isUnsplashEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>

                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="API KEY"
                      value={settings.unsplashServer_apiKey}
                      onChange={(e) =>
                        handleInputChange(e, "unsplashServer_apiKey")
                      }
                      disabled={!settings.unsplashServer_isUnsplashEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>

                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="ACCESS KEY"
                      value={settings.unsplashServer_acessKey}
                      onChange={(e) =>
                        handleInputChange(e, "unsplashServer_acessKey")
                      }
                      disabled={!settings.unsplashServer_isUnsplashEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>

                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="SECRET  KEY"
                      value={settings.unsplashServer_secretKey}
                      onChange={(e) =>
                        handleInputChange(e, "unsplashServer_secretKey")
                      }
                      disabled={!settings.unsplashServer_isUnsplashEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>
              </div>

              <div className="text-center">
                {/* Save Button */}
                <button
                  className="bg-dashcolor text-white px-6 py-2 rounded-full"
                  onClick={handleSave}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>

          <div className="p-6 mt-1">
            {/* Voice Server Section */}
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div className="flex">
                <div className="voice-icon w-15 h-15 mr-1">
                  <img src={gif_icon} className="w-full" alt="" />
                </div>
                <div>
                  <h3 className="text-xl font-bold">
                    Banco de GIF y STICKER TENOR
                  </h3>
                  <div className="text-gray-600 mb-4">
                    Desde esta sección se podrá configurar el servicio por medio
                    de API de la plataforma de TENOR
                  </div>
                </div>
              </div>

              {/* Custom Server Configuration */}
              <div className="mb-6">
                <h4 className="font-semibold">Configurar TENOR</h4>
                <p className="text-gray-600">
                  Agrega a continuación los campos solicitados
                </p>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mt-2 mb-4 rounded"
                      placeholder="URL API"
                      value={settings.stickerServer_urlApi}
                      onChange={(e) =>
                        handleInputChange(e, "stickerServer_urlApi")
                      }
                      disabled={!settings.stickerServer_isStickerEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6 mt-4">
                    {/* Toggle Switch for Custom Server */}
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={settings.stickerServer_isStickerEnabled}
                        onChange={(e) =>
                          handleToggleChange(
                            e,
                            "stickerServer_isStickerEnabled"
                          )
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="flex">
                  <div className="w-3/4">
                    <input
                      type="text"
                      className="border p-2 w-full mb-4 rounded"
                      placeholder="Application ID"
                      value={settings.stickerServer_id}
                      onChange={(e) => handleInputChange(e, "stickerServer_id")}
                      disabled={!settings.stickerServer_isStickerEnabled}
                    />
                  </div>
                  <div className="w-1/4 ml-6"></div>
                </div>
              </div>

              <div className="text-center">
                {/* Save Button */}
                <button
                  className="bg-dashcolor text-white px-6 py-2 rounded-full"
                  onClick={handleSave}
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Create Category Modal */}
      <CreateCategoryModal
        isOpen={isCreateCategoryModalOpen}
        onClose={() => setIsCreateCategoryModalOpen(false)}
        onCategoryCreated={handleCategoryCreated}
      />
    </DashboardLayout>
  );
};

export default ConfigurationPage;