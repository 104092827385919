import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { AppDispatch } from "../../redux/store";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoCloseCircleOutline } from "react-icons/io5";

import "./Signin.css";

import logoImage from "../../assets/img/logo.png";
import carousel_img1 from "../../assets/img/carousel1.png";
import carousel_img2 from "../../assets/img/carousel2.png";
import carousel_img3 from "../../assets/img/carousel3.png";
import carousel_img4 from "../../assets/img/carousel4.png";

const carouselData = [
  {
    image: carousel_img1, // Replace with actual image path or URL
    title: '"La creatividad es la inteligencia divirtiéndose."',
    user: "Albert Einstein",
  },
  {
    image: carousel_img2, // Replace with actual image path or URL
    title:
      "La imaginación es el principio de la creación. Imaginas lo que deseas, persigues lo que imaginas y finalmente creas lo que persigues.",
    user: "George Bernard Shaw",
  },
  {
    image: carousel_img3, // Replace with actual image path or URL
    title:
      "La creatividad es inventar, experimentar, crecer, tomar riesgos, romper reglas, cometer errores y divertirse.",
    user: "Mary Lou Cook",
  },
  {
    image: carousel_img4, // Replace with actual image path or URL
    title: "La innovación distingue a un líder de un seguidor.",
    user: "Steve Jobs",
  },
  // Add more items as needed
];

const apiPort = process.env.REACT_APP_API_PORT;

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await axios.post(`${apiPort}/api/auth/signup`, { name, email, password });
      navigate("/signin");
    } catch (err) {
      toast.error(
        <div className="custom-toast flex">
          <IoCloseCircleOutline className="custom-icon" />
          <div>
            <strong>Error signup into your account</strong>
            <p>Por favor verifique su información.</p>
          </div>
        </div>,
        {
          className: "error-toast",
          autoClose: 300000,
          hideProgressBar: true,
        }
      );
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div className="min-h-screen container flex items-center justify-center bg-dashcolor">
      <ToastContainer />
      <div className="flex w-[60%] py-10">
        <div className="bg-authbg rounded rounded-lg grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-3">
          <div className="">
            <form
              onSubmit={handleSubmit}
              className="p-8 rounded-lg"
            >
               <div className="flex">
                <div className="voice-icon w-[150px] h-13 mb-1">
                  <img src={logoImage} className="w-full" alt="" />
                </div>
              </div>
              <h2 className="text-xl text-white">La combinación perfecta:</h2>

              <div className="text-white mb-4">VidBuilder y tú.</div>

              <div className="text-white mb-4">
                Para crear tu cuenta, simplemente utiliza tu correo electrónico y contraseña.
              </div>

              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mb-4 p-2 border rounded w-full"
                placeholder="Aquí está tu nombre"
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mb-4 p-2 border rounded w-full"
                placeholder="Aquí tu correo electrónico"
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mb-4 p-2 border rounded w-full"
                placeholder="Aquí tu contraseña"
              />
              <button
                type="submit"
                className="bg-dashcolor text-white p-2 rounded w-full mb-4"
              >
                Sign Up
              </button>

              <p className="text-center text-white">
                <Link to="/signin"> Sign In</Link>
              </p>
            </form>
          </div>

          <div className="carousel bg-authbg">
            <Slider {...settings} className="relative mt-3 mb-2">
              {carouselData.map((item, index) => (
                <div key={index} className="carousel-item">
                  <img
                    src={item.image || ""}
                    alt={item.title}
                    className="carousel-image"
                  />
                  <div className="carousel-content">
                    <div>
                      <h3 className="text-xl mb-2">{item.title}</h3>
                      <p>{item.user}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
