import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaSearch,
  FaEdit,
  FaTrash,
  FaBan,
} from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";

import DashboardLayout from "../../layouts/DashboardLayout";
import { useNavigate } from "react-router-dom";
import EditPlanModal from "./EditPlanModal";

interface Plan {
  _id: string;
  name: string;
  videosPerMonth: number;
  duration: string;
  languages: number;
  templates: boolean;
  monthlyCost: number;
  yearlyCost: number;
  status: string;
}

const apiPort = process.env.REACT_APP_API_PORT;


const PlanManagement = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [filteredPlans, setFilteredPlans] = useState<Plan[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null); // State for selected plan
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal open/close

  const navigate = useNavigate();

  // Fetch plans from backend
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get<Plan[]>(
          `${apiPort}/api/plans`
        );
        setPlans(response.data);
        setFilteredPlans(response.data);
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };
    fetchPlans();
  }, []);

  // Filter plans by search term or status
  useEffect(() => {
    let filtered = plans.filter((plan) =>
      plan.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (statusFilter) {
      filtered = filtered.filter((plan) => plan.status === statusFilter);
    }
    setFilteredPlans(filtered);
  }, [searchTerm, statusFilter, plans]);

  // Delete a plan
  const deletePlan = async (id: string) => {
    try {
      await axios.delete(`${apiPort}/api/plans/${id}`);
      setPlans(plans.filter((plan) => plan._id !== id));
    } catch (error) {
      console.error("Error deleting plan:", error);
    }
  };

  // Handle opening the Edit Modal
  const openEditModal = (plan: Plan) => {
    setSelectedPlan(plan); // Set the selected plan
    setIsModalOpen(true); // Open the modal
  };

  // Handle saving the updated plan
  const handleSave = async (updatedPlan: Plan) => {
    try {
      const response = await axios.put(
        `${apiPort}/api/plans/${updatedPlan._id}`,
        updatedPlan
      );
      setPlans(
        plans.map((plan) =>
          plan._id === updatedPlan._id ? response.data : plan
        )
      );
      setIsModalOpen(false); // Close the modal after saving
    } catch (error) {
      console.error("Error updating plan:", error);
    }
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        {/* Search and Filter */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center w-1/3 relative">
            <input
              type="text"
              placeholder="Buscar por nombre por nombre del plan"
              className="border p-2 rounded w-full rounded-full"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="absolute top-0 right-0 ml-2 bg-dashcolor text-white p-2 rounded-[25px] rounded flex items-center">
              <FaSearch className="mr-2" /> Buscar
            </button>
          </div>
          <div className="flex justify-end w-1/2">
            {/* Filter Buttons */}
            <div className="flex space-x-2 mr-2">
              <button
                onClick={() => setStatusFilter("")}
                className={`p-2 rounded rounded-[25px] px-4  ${
                  statusFilter === ""
                    ? "bg-dashcolor text-white"
                    : "bg-planInactive text-gray-700"
                }`}
              >
                Todos
              </button>
              <button
                onClick={() => setStatusFilter("Activo")}
                className={`p-2 rounded rounded-[25px] px-4 ${
                  statusFilter === "Activo"
                    ? "bg-dashcolor text-white"
                    : "bg-planInactive text-gray-700"
                }`}
              >
                Plan Activo
              </button>
              <button
                onClick={() => setStatusFilter("Inactivo")}
                className={`p-2 rounded rounded-[25px] px-4 ${
                  statusFilter === "Inactivo"
                    ? "bg-dashcolor text-white"
                    : "bg-planInactive text-gray-700"
                }`}
              >
                Plan Inactivo
              </button>
            </div>
            <div className="flex space-x-2">
              {/* Create Plan Button */}
              <button
                className="bg-dashcolor text-white p-2 rounded flex rounded-[25px] px-4 items-center"
                onClick={() => navigate("/create-plan")}
              >
                Crear Plan
              </button>
            </div>
          </div>
        </div>

        {/* Plan List */}
        <table className="min-w-full bg-white shadow-md rounded mb-4">
          <thead>
            <tr>
              <th className="text-left p-4">Nombre del plan</th>
              <th className="text-left p-4">Videos mes</th>
              <th className="text-left p-4">Duración</th>
              <th className="text-left p-4">Idiomas</th>
              <th className="text-left p-4">Plantillas</th>
              <th className="text-left p-4">Costos</th>
              <th className="text-left p-4">Estado</th>
              <th className="text-left p-4">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {filteredPlans.map((plan) => (
              <tr key={plan._id} className="border-b">
                <td className="p-4 text-center">{plan.name}</td>
                <td className="p-4 text-center">{plan.videosPerMonth}</td>
                <td className="p-4 text-center">{plan.duration}</td>
                <td className="p-4 text-center">{plan.languages}</td>
                <td className="p-4">
                  {plan.templates ? (
                    <FiCheckCircle className="text-green-500 m-auto" />
                  ) : (
                    <FaBan className="text-red-500 m-auto" />
                  )}
                </td>
                <td className="p-4 text-center">
                  <p>Mes: USD {plan.monthlyCost.toFixed(2)}</p>
                  <p>Año: USD {plan.yearlyCost.toFixed(2)}</p>
                </td>
                <td className="p-4 text-center">
                  <span
                    className={`px-2 py-1 rounded-full text-sm ${
                      plan.status === "Activo"
                        ? "bg-green-600 text-white px-5 py-2"
                        : plan.status === "Inactivo"
                        ? "border border-orange-500 px-3 text-orange-500 py-2"
                        : "border border-gray-500 text-gray-500 py-2"
                    }`}
                  >
                    {plan.status}
                  </span>
                </td>
                <td className="flex p-4 text-center items-center">
                  <FaEdit
                    className="cursor-pointer bg-green mx-2 mt-3"
                    onClick={() => openEditModal(plan)}
                  />
                  <FaTrash
                    onClick={() => deletePlan(plan._id)}
                    className="cursor-pointer bg-green mt-3"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Edit Plan Modal */}
        {selectedPlan && (
          <EditPlanModal
            plan={selectedPlan}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onSave={handleSave}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default PlanManagement;
