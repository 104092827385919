import React, { useState } from "react";
import axios from "axios";

const CreateCategoryModal: React.FC<{ isOpen: boolean; onClose: () => void; onCategoryCreated: (category: any) => void }> = ({ isOpen, onClose, onCategoryCreated }) => {
  const [categoryName, setCategoryName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const apiPort = process.env.REACT_APP_API_PORT;


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post(`${apiPort}/api/categories`, {
        name: categoryName,
      });
      onCategoryCreated(response.data); // Notify parent of new category
      setCategoryName(""); // Reset form
    } catch (error) {
      console.error("Error creating category:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-lg">
        <h2 className="text-xl font-bold mb-4">Crear nueva categoría</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Nombre de la categoría</label>
            <input
              type="text"
              className="border p-2 w-full rounded"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              required
            />
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className="mr-4 bg-gray-300 px-4 py-2 rounded"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className={`bg-dashcolor text-white px-4 py-2 rounded ${isSubmitting ? "opacity-50" : ""}`}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Creando..." : "Crear"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCategoryModal;
