import React from "react";
import designer_avatar from '../assets/img/designer.png';
import programmer_avatar from '../assets/img/programmer.png';


const clients = [
  {
    name: "Dina Duran",
    role: "Diseñador",
    plan: "Plan: Básico - Pago mensual",
    description:
      "Soy una diseñadora gráfica, que desea expandir sus conocimientos...",
    location: "Londres, Inglaterra",
    imgsrc: `${designer_avatar}`,
  },
  {
    name: "Fredy Trujillo",
    role: "Programador",
    plan: "Plan: Profesional - Pago anual",
    description:
      "Soy un programador que busca integrar nuevas funcionalidades...",
    location: "Bogotá, Colombia",
    imgsrc: `${programmer_avatar}`,
  },
  {
    name: "Camilo Gutierrez",
    role: "CEO",
    plan: "Plan: Básico - Pago mensual",
    description:
      "Soy propietario de una empresa que busca expandir sus negocios...",
    location: "Bogotá, Colombia",
    imgsrc: `${programmer_avatar}`,
  },
];

const NewClients = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-4">Nuevos clientes</h2>
      <div className="grid grid-cols-3 gap-4">
        {clients.map((client, index) => (
          <div key={index} className="p-4 border rounded-lg shadow-sm">
            <div className="flex justify-between">
              <div>
                <h6 className="text-gray-400 text-sm font-semibold mb-2">
                  {client.role}
                </h6>
                <h3 className="font-semibold">{client.name}</h3>
              </div>
              <div className="flex justify-end">
                <img src={client.imgsrc} alt="" />
              </div>
            </div>

            <p className="text-purple-600 mt-1">{client.plan}</p>
            <p className="text-sm text-gray-600 mt-2">{client.description}</p>

            <div className="flex justify-between">
              <div>
                <button className="bg-blue-100 text-purple-600 px-4 py-2 mt-4 rounded-full  hover:bg-purple-200 transition-all">
                  Ver información
                </button>
              </div>
              <div className="flex justify-end">
                <p className="mt-[25px] text-gray-400 text-sm">{client.location}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewClients;
