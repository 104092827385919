import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";

const apiPort = process.env.REACT_APP_API_PORT;


const CreatePlan = () => {
  const [name, setName] = useState("");
  const [diskSpace, setDiskSpace] = useState(0);
  const [videosPerMonth, setVideosPerMonth] = useState(0);
  const [videoUnlimit, setVideoUnlimit] = useState(false);
  const [duration, setDuration] = useState("");
  const [durationCheck, setDurationCheck] = useState(false);
  const [languages, setLanguages] = useState(1);
  const [languageCheck, setLanguageCheck] = useState(false);
  const [voices, setVoices] = useState(0);
  const [pixelBank, setPixelBank] = useState(false);
  const [pixabayBank, setPixabayBank] = useState(false);
  const [unsplashBank, setUnsplashBank] = useState(false);
  const [autherizedUsers, setAutherizedUsers] = useState(0);
  const [templates, setTemplates] = useState(false);
  const [soundBank, setSoundBank] = useState(false);
  const [accessPro, setAccessPro] = useState(false);
  const [monthlyCost, setMonthlyCost] = useState(0);
  const [yearlyCost, setYearlyCost] = useState(0);
  const [status, setStatus] = useState("Activo");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [active, setActive] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiPort}/api/plans`, {
        name,
        diskSpace,
        videosPerMonth,
        videoUnlimit,
        duration,
        durationCheck,
        languages,
        languageCheck,
        voices,
        pixelBank,
        pixabayBank,
        unsplashBank,
        autherizedUsers,
        templates,
        soundBank,
        accessPro,
        monthlyCost,
        yearlyCost,
        status,
        active,
      });

      if (response.data) {
        setSuccessMessage("Plan created successfully");
        setErrorMessage("");
        // Optionally redirect to plan management after creation
        setTimeout(() => navigate("/plan-management"), 2000);
      }
    } catch (error) {
      setErrorMessage("Failed to create plan. Please try again.");
      console.error(error);
    }
  };

  return (
    <DashboardLayout>
      <div className="container p-6 bg-white rounded">
        {errorMessage && (
          <div className="text-red-500 mb-4">{errorMessage}</div>
        )}
        {successMessage && (
          <div className="text-green-500 mb-4">{successMessage}</div>
        )}

        <div className="flex justify-between mb-6">
          <div>
            <h2 className="text-xl font-bold mb-4">Gestionar plan</h2>
          </div>

          <div className="flex justify-end items-center">
            <label className="mr-2 text-gray-600">Estado</label>
            <div
              className={`relative inline-block mr-2 w-12 h-6 ${
                active ? "bg-dashcolor" : "bg-gray-300"
              } rounded-full cursor-pointer`}
              onClick={() => setActive(!active)}
            >
              <span
                className={`absolute left-1 top-1 h-4 w-4 bg-white rounded-full transition-transform ${
                  active ? "transform translate-x-6" : ""
                }`}
              ></span>
            </div>
            <button
              className="bg-gray-300 text-gray-600 px-4 py-2 rounded-full mr-4"
              onClick={() => navigate("/plan-management")}
            >
              Cancelar
            </button>

            <button
              onCanPlay={handleSubmit}
              className="bg-dashcolor text-white px-4 py-2 rounded-full"
            >
              Guardar cambios
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex mb-4">
            <h3 style={{ textTransform: "uppercase" }}>GENERAL</h3>
            <div className="spliter"></div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-3">
            <div className="mb-4">
              <label className="block text-sm">Plan Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border-b-2 p-2 rounded w-full"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm">Disk space( in Megabytes)</label>
              <input
                type="number"
                value={diskSpace}
                onChange={(e) => setDiskSpace(Number(e.target.value))}
                className="border-b-2 p-2 rounded w-full"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm">Videos Per Month</label>
              <input
                type="number"
                value={videosPerMonth}
                onChange={(e) => setVideosPerMonth(Number(e.target.value))}
                className="border-b-2 p-2 rounded w-full"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm">
                Duration (e.g., 3 minutes)
              </label>
              <input
                type="text"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                className="border-b-2 p-2 rounded w-full"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm">Number of Languages</label>
              <input
                type="number"
                value={languages}
                onChange={(e) => setLanguages(Number(e.target.value))}
                className="border-b-2 p-2 rounded w-full"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm">Number of Voices</label>
              <input
                type="number"
                value={voices}
                onChange={(e) => setVoices(Number(e.target.value))}
                className="border-b-2 p-2 rounded w-full"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm">PEXEL Bank</label>
              <select
                value={pixelBank ? "yes" : "no"}
                onChange={(e) => setPixelBank(e.target.value === "yes")}
                className="border-b-2 p-2 rounded w-full"
                required
              >
                <option value="yes">Si</option>
                <option value="no">No</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm">PIXABAY Bank</label>
              <select
                value={pixabayBank ? "yes" : "no"}
                onChange={(e) => setPixabayBank(e.target.value === "yes")}
                className="border-b-2 p-2 rounded w-full"
                required
              >
                <option value="yes">Si</option>
                <option value="no">No</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm">UNSPLASH Bank</label>
              <select
                value={unsplashBank ? "yes" : "no"}
                onChange={(e) => setUnsplashBank(e.target.value === "yes")}
                className="border-b-2 p-2 rounded w-full"
                required
              >
                <option value="yes">Si</option>
                <option value="no">No</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm">Authorized users</label>
              <input
                type="number"
                value={autherizedUsers}
                onChange={(e) => setAutherizedUsers(Number(e.target.value))}
                className="border-b-2 p-2 rounded w-full"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm">Templates Available</label>
              <select
                value={templates ? "yes" : "no"}
                onChange={(e) => setTemplates(e.target.value === "yes")}
                className="border-b-2 p-2 rounded w-full"
                required
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm">Sound Bank</label>
              <select
                value={soundBank ? "yes" : "no"}
                onChange={(e) => setSoundBank(e.target.value === "yes")}
                className="border-b-2 p-2 rounded w-full"
                required
              >
                <option value="yes">Si</option>
                <option value="no">No</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm">Access to PRO Content</label>
              <select
                value={accessPro ? "yes" : "no"}
                onChange={(e) => setAccessPro(e.target.value === "yes")}
                className="border-b-2 p-2 rounded w-full"
                required
              >
                <option value="yes">Si</option>
                <option value="no">No</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-sm">Monthly Cost (USD)</label>
              <input
                type="number"
                value={monthlyCost}
                onChange={(e) => setMonthlyCost(Number(e.target.value))}
                className="border-b-2 p-2 rounded w-full"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm">Yearly Cost (USD)</label>
              <input
                type="number"
                value={yearlyCost}
                onChange={(e) => setYearlyCost(Number(e.target.value))}
                className="border-b-2 p-2 rounded w-full"
                required
              />
            </div>

            {/* <div className="mb-4">
                <label className="block text-sm">Status</label>
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="border-b-2 p-2 rounded w-full"
                  required
                >
                  <option value="Activo">Activo</option>
                  <option value="Inactivo">Inactivo</option>                 
                </select>
              </div> */}
          </div>
          <div className="text-center">
            <button
              onClick={() => navigate("/plan-management")}
              className="bg-gray-300 text-gray-600 px-4 py-2 rounded-full mr-4"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="bg-dashcolor text-white px-4 py-2 rounded-full"
            >
              Guardar cambios
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default CreatePlan;
