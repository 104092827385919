import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaStar, FaUser, FaSearch, FaFilter } from "react-icons/fa";
import DashboardLayout from "../../layouts/DashboardLayout";
import { GiMaterialsScience } from "react-icons/gi";
import {
  MdKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
  MdViewList,
  MdViewModule,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import soundIcon from '../../assets/icons/sound.svg';
import soundTxtIcon from "../../assets/icons/soundTxticon.svg";
import SoundUploadModal from "./SoundUploadModal";
import EditSoundModal from "./EditSoundModal"; 

export interface Sound {
  _id: string;
  name: string;
  planType: string;
  status: string;
  txtUrl: string;
  soundUrl: string;
}

interface SoundResponse {
  sounds: Sound[];
  totalPages: number;
}

const apiPort = process.env.REACT_APP_API_PORT;


const SoundsBankPage = () => {
  const navigate = useNavigate();
  const [sounds, setSounds] = useState<Sound[]>([]);
  const [selectedSound, setSelectedSound] = useState<Sound | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [planTypeFilter, setPlanTypeFilter] = useState(""); // Normal or Pro
  const [viewType, setViewType] = useState<"grid" | "list">("grid"); // View type toggle (grid/list)
  const [showProSounds, setShowProSounds] = useState(false); // Toggle for showing Pro templates
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false); // State for sound upload modal

  // Fetch sounds from the backend
  useEffect(() => {
    const fetchSounds = async () => {
      try {
        const response = await axios.get<SoundResponse>(
          `${apiPort}/api/sounds?page=${currentPage}&limit=5`
        );
        setSounds(response.data.sounds);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    fetchSounds();
  }, [currentPage]);

  // Filter sounds based on plan type and category
  const filteredSounds = sounds.filter((sound) => {
    const isPro = showProSounds
      ? sound.planType === "Pro"
      : sound.planType === "Normal" || sound.planType === "Pro";
    return isPro && sound.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // Handle sound preview
  const handleSoundClick = (sound: Sound) => {
    setSelectedSound(sound);
  };

  const handleDuplicate = async () => {
    if (selectedSound) {
      try {
        const response = await axios.post(
          `${apiPort}/api/sounds/${selectedSound._id}/duplicate`
        );
        setSounds([...sounds, response.data]);
      } catch (error) {
        console.error("Error duplicating sound:", error);
      }
    }
  };

  const handleDelete = async () => {
    if (selectedSound) {
      try {
        await axios.delete(
          `${apiPort}/api/sounds/${selectedSound._id}`
        );
        setSounds(sounds.filter((t) => t._id !== selectedSound._id));
        setSelectedSound(null);
      } catch (error) {
        console.error("Error deleting template:", error);
      }
    }
  };

  // Handle successful upload (refresh the sounds list)
  const handleSuccess = () => {
    setIsUploadModalOpen(false); // Close the modal after successful upload
    // Reload sounds after upload
    axios
      .get<SoundResponse>(
        `${apiPort}/api/sounds?page=${currentPage}&limit=5`
      )
      .then((response) => {
        setSounds(response.data.sounds);
        setTotalPages(response.data.totalPages);
      })
      .catch((error) => console.error("Error reloading sounds:", error));
  };

  return (
    <DashboardLayout>
      <div className="p-6">
        {/* Search, Filters, and View Toggles */}
        <div className="flex justify-between items-center mb-6 space-x-4">
          <div className="flex w-3/4 relative">
            <input
              type="text"
              placeholder="Buscar por nombre o descripción"
              className="border p-3 rounded w-full rounded-tr-[10px] rounded-br-[10px]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute top-1 right-2 flex">
              <button className="bg-gray-300 p-2 px-4 rounded rounded-[25px] flex items-center mr-2">
                <FaFilter className="mr-2" /> Filtros
              </button>
              <button className="bg-dashcolor text-white p-2 px-4 rounded rounded-[25px] flex items-center">
                <FaSearch className="mr-2" /> Buscar
              </button>
            </div>
          </div>
          <div className="flex justify-end items-center space-x-4 w-1/4">
            <button
              className="bg-dashcolor text-white p-2 px-4 rounded rounded-[25px] flex items-center mr-2"
              onClick={() => setIsUploadModalOpen(true)}
            >
              Cargar sonido
            </button>
          </div>
        </div>

        <div className="flex">
          <div className="w-3/4">
            <div className="flex justify-end items-center mb-3">
              <div className="w-7 h-7 p-1 border border-rounded rounded-full bg-yellow-400 mr-2">
                <FaStar className="text-white" />
              </div>
              <label className="mr-2 text-gray-600">Sonidos PRO</label>
              {/* Custom Toggle Button */}
              <div
                className={`relative inline-block mr-2 w-12 h-6 ${
                  showProSounds ? "bg-yellow-500" : "bg-gray-300"
                } rounded-full cursor-pointer`}
                onClick={() => setShowProSounds(!showProSounds)}
              >
                <span
                  className={`absolute left-1 top-1 h-4 w-4 bg-white rounded-full transition-transform ${
                    showProSounds ? "transform translate-x-6" : ""
                  }`}
                ></span>
              </div>
              {/* View Toggle Buttons */}
              <button
                onClick={() => setViewType("grid")}
                className={`p-2 mr-2 ${
                  viewType === "grid"
                    ? "bg-dashcolor text-white"
                    : "bg-gray-300"
                } rounded-full`}
              >
                <MdViewModule />
              </button>
              <button
                onClick={() => setViewType("list")}
                className={`p-2 ${
                  viewType === "list"
                    ? "bg-dashcolor text-white"
                    : "bg-gray-300"
                } rounded-full`}
              >
                <MdViewList />
              </button>
            </div>
            {/* Templates View */}
            {viewType === "grid" ? (
              <div className="grid grid-cols-4 gap-4">
                {filteredSounds.length > 0 ? (
                  filteredSounds.map((sound) => (
                    <div
                      key={sound._id}
                      className="bg-white shadow-md rounded-lg p-4 cursor-pointer relative hover:shadow-lg transition"
                      onClick={() => handleSoundClick(sound)}
                    >
                      <div className="items-center mb-2 text-center">
                        <div className="absolute top-0 right-2">
                          {sound.planType === "Pro" ? (
                            <div className="w-7 h-7 p-1 border border-rounded rounded-full bg-yellow-400">
                              <FaStar className="text-white" />
                            </div>
                          ) : null}
                        </div>
                        <img
                          src={soundIcon}
                          alt="soundIcon"
                          className="w-full h-[120px] rounded"
                        />
                        <p>{sound.name}</p>
                      </div>

                      <div className="absolute bottom-4 right-4">
                        {sound.status === "Active" ? (
                          <div className="w-3 h-3 rounded-full bg-dashcolor"></div>
                        ) : (
                          <div className="w-3 h-3 rounded-full bg-gray-600"></div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="col-span-4 text-center text-gray-500">
                    No se encontraron sonidos.
                  </p>
                )}
              </div>
            ) : (
              <div className="space-y-4">
                {filteredSounds.length > 0 ? (
                  filteredSounds.map((sound) => (
                    <div
                      key={sound._id}
                      className="bg-white shadow-md rounded-lg p-4 flex items-center justify-between hover:shadow-lg transition"
                      onClick={() => handleSoundClick(sound)}
                    >
                      <div className="flex items-center space-x-4">
                        <img
                          src={soundIcon}
                          alt="soundIcon"
                          className="w-full h-[120px] rounded"
                        />
                        <div>
                          <p className="font-bold">{sound.name}</p>
                        </div>
                      </div>
                      <div>
                        <p
                          className={`text-sm ${
                            sound.status === "Active"
                              ? "text-green-500"
                              : "text-gray-500"
                          }`}
                        >
                          {sound.status}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-center text-gray-500">
                    No se encontraron sonidos.
                  </p>
                )}
              </div>
            )}

            {/* Pagination */}
            <div className="flex justify-between items-center mt-6">
              <span>
                Mostrando {filteredSounds.length} de {sounds.length} sonidos
              </span>
              <div className="flex space-x-2">
                <button
                  className="px-4 py-2 bg-white text-dashcolor rounded-full flex"
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                >
                  <MdKeyboardDoubleArrowLeft className="mt-1 mr-1" /> Anterior
                </button>
                <span className="mt-2">
                  Página {currentPage} de {totalPages}
                </span>
                <button
                  className="px-4 py-2 bg-white text-dashcolor rounded-full flex"
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                >
                  Siguiente{" "}
                  <MdOutlineKeyboardDoubleArrowRight className="mt-1 ml-1" />
                </button>
              </div>
            </div>
          </div>

          {/* Right side: Preview panel */}
          <div className="w-1/4 bg-white p-4 rounded-lg ml-2">
            <h2 className="text-lg font-bold mb-4">Vista previa</h2>
            {selectedSound ? (
              <div>
                <div className="mt-4 relative">
                  <audio
                    className="w-full max-w-3xl rounded-lg shadow-lg"
                    controls
                  >
                    <source
                      src={`${apiPort}${selectedSound.soundUrl}`}
                      type="audio/ogg"
                    />
                    <source
                      src={`${apiPort}${selectedSound.soundUrl}`}
                      type="audio/mp3"
                    />
                    Your browser does not support the audio element.
                  </audio>
                  <div className="absolute bottom-[-10px] right-0">
                    {selectedSound.planType === "Pro" ? (
                      <div className="w-7 h-7 p-1 border border-rounded rounded-full bg-yellow-400">
                        <FaStar className="text-white" />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="flex justify-between  mt-3">
                  <div className="flex">
                    <div className="bg-dashcolor p-2 w-8 h-8 rounded-full mt-2">
                      <FaUser className="text-white" />
                    </div>
                    <div className="ml-2">
                      <div className="font-bold">Créditos</div>
                      <div>{selectedSound.name}</div>
                    </div>
                  </div>

                  <div className="flex justify-end text-right mt-3">
                    <a
                      href={`${apiPort}${selectedSound.txtUrl}`}
                      download={selectedSound.name + ".txt"}
                      target="_blank"
                    >
                      <img
                        src={soundTxtIcon}
                        alt="soundTxtIcon"
                        className="rounded w-5 h-5"
                      />
                    </a>
                  </div>
                </div>

                <div className="flex justify-between mt-4">
                  <button
                    onClick={() => setIsEditModalOpen(true)}
                    className="px-4 py-2 bg-dashcolor text-white rounded-full mr-1"
                  >
                    Editar
                  </button>

                  <button
                    onClick={handleDelete}
                    className="px-4 py-2 bg-gray-500 text-white rounded-full"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            ) : (
              <p className="text-gray-400 text-center">
                Haz clic en una plantilla para ver más detalles.
              </p>
            )}
          </div>
          {/* Sound Upload Modal */}
          <SoundUploadModal
            isOpen={isUploadModalOpen}
            onClose={() => setIsUploadModalOpen(false)}
            onSuccess={handleSuccess} // Refresh the sound list after successful upload
          />

          <EditSoundModal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            onSuccess={handleSuccess}
            sound={selectedSound} // Pass the selected sound to edit
          />
          
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SoundsBankPage;
