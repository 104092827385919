import React from "react";
import { FaPuzzlePiece } from "react-icons/fa";


const activities = [
  { name: "@DinaDuran", action: "ha creado un nuevo video.", time: "3h atras" },
  {
    name: "@DinaDuran",
    action: "a borrado un video en la plataforma.",
    time: "5h atras",
  },
  {
    name: "@FredyTrujillo",
    action: "se a registrado en la plataforma.",
    time: "12h atras",
  },
  {
    name: "@CamiloGutierrez",
    action: "se a registrado en la plataforma.",
    time: "12h atras",
  },
];

const RecentActivity = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-lg font-semibold">Actividad reciente</h2>
      <ul className="mt-4">
        {activities.map((activity, index) => (
          <li key={index} className="flex items-center mb-4">
            <div className="p-4 rounded-lg border border-gray-300 border-rounded-[20px] flex justify-center items-center text-white">
              {/* Placeholder Icon */}
              <FaPuzzlePiece color="purple" />
            </div>
            <div className="ml-4">
              <p>
                <span className="font-semibold">{activity.name}</span>{" "}
                {activity.action}
              </p>
              <p className="text-gray-400 text-sm">{activity.time}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentActivity;
