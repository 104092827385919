import React from "react";

interface CardProps {
  title: string;
  value: string;
  imgsrc: string;
  icon: JSX.Element;
  bgColor: string;
  classProps: string;
}

const Card: React.FC<CardProps> = ({
  title,
  value,
  icon,
  imgsrc,
  bgColor,
  classProps,
}) => {
  return (
    <div
      className={`${classProps} p-6 rounded-lg shadow-md flex justify-between items-center text-white space-x-4`}
    >
      <div className="p-4 rounded-lg border border-white border-rounded-full">
        <img
          src={imgsrc}
          alt="Logo"
          className={`transition-all duration-300 overflow-hidden w-5`}
        />
      </div>
      <div className="justify-end">
        <h3 className="text-lg text-right">{title}</h3>
        <p className="text-2xl text-right font-bold">{value}</p>
      </div>
    </div>
  );
};

export default Card;
