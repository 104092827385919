import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidebarState {
  selectedItem: string;
}

const initialState: SidebarState = {
  selectedItem: "Dashboard", // Default selected item
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSelectedItem(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload;
    },
  },
});

export const { setSelectedItem } = sidebarSlice.actions;

export default sidebarSlice.reducer;
